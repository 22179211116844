import StatsCard from "components/general/StatsCard";
import TransactionAccordion from "./transactionAccordion";
import { newDropDownOptions } from "interfaces/transactions";
import { EarningByTrans, EarningByUser, GwalaEarningsByFees, TransStats } from "services/api/types";
import { useGetTransactionsStats } from "services/api/transactionPaths";
import { useEffect, useState } from "react";

type TransStatsProps = {
  transStats: TransStats;
  earningsByUser: EarningByUser;
  earningsByTrans: EarningByTrans;
  gwalaEarningsByFees : GwalaEarningsByFees;
};
const TransactionCards = ({ transStats, earningsByTrans, earningsByUser, gwalaEarningsByFees }: TransStatsProps) => {
  const [transStatsParams, setTransStatsParams] = useState({
    totalWalletBalance: "allTime",
    totalActiveWallet: "allTime",
    totalGroupBalance: "allTime",
    totalInflow: "allTime",
  });
  const handleParamsChange = (val: string, name: string) => {
    setTransStatsParams({
      ...transStatsParams,
      [name]: val,
    });
  };
  const {
    refetch: refetchStats,
    isRefetching
  } = useGetTransactionsStats({
    queryParams: transStatsParams,
  });

  useEffect(() => {
    refetchStats();
  }, [transStatsParams, refetchStats]);
  return (
    <div className="">
      <div className={`flex gap-4 mt-5 ${isRefetching ? "opacity-30" : ""}`}>
        <StatsCard
          header="TOTAL WALLET BALANCE"
          dropDownOptions={newDropDownOptions}
          num={transStats?.totalWalletBalance.value!}
          text={transStatsParams.totalWalletBalance}
          name="totalWalletBalance"
          percent={`${transStats?.totalWalletBalance.change}`}
          decimal={true}
          handleSelect={handleParamsChange}
        />
        <StatsCard
          header="TOTAL ACTIVE WALLET"
          dropDownOptions={newDropDownOptions}
          num={transStats?.totalActiveWalletsCount.value!}
          text={transStatsParams.totalActiveWallet}
          name="totalActiveWallet"
          percent={`${transStats?.totalActiveWalletsCount.change}`}
          decimal={false}
          width={332}
          handleSelect={handleParamsChange}
        />
        <StatsCard
          header="GROUP WALLET BALANCE"
          dropDownOptions={newDropDownOptions}
          num={transStats?.totalGroupBalance.value!}
          text={transStatsParams.totalGroupBalance}
          name="totalGroupBalance"
          percent={`${transStats?.totalGroupBalance.change}`}
          decimal={true}
          handleSelect={handleParamsChange}
        />
        <StatsCard
          header="TOTAL INFLOW"
          dropDownOptions={newDropDownOptions}
          num={transStats?.totalInflow.value!}
          text={transStatsParams.totalInflow}
          name="totalInflow"
          percent={`${transStats?.totalInflow.change}`}
          decimal={true}
          handleSelect={handleParamsChange}
        />
      </div>
      <div className="flex gap-4 mt-4 flex-wrap">
        <TransactionAccordion
          data={transStats?.totalTransactionVolumn!}
          header="TOTAL TRANSACTION VOLUME"
          subHeader="Transaction by volume"
        />
        <TransactionAccordion
          data={transStats?.totalTransactionValue!}
          header="TOTAL TRANSACTION VALUE"
          subHeader="Transaction by value"
        />
        <TransactionAccordion
          data={transStats?.transactionByChannel!}
          header="TRANSACTION BY CHANNEL"
          subHeader="Transaction breakdown by channel"
        />
        <TransactionAccordion
          data={earningsByUser}
          header="EARNINGS BY USERS"
          subHeader="Earnings breakdown by users"
        />
        <TransactionAccordion
          data={earningsByTrans}
          header="EARNINGS BY TRANSACTIONS"
          subHeader="Earnings breakdown by transactions"
        />
        <TransactionAccordion
          data={gwalaEarningsByFees}
          header="EARNINGS BY FEES"
          subHeader="Earnings breakdown by fees"
        />
      </div>
    </div>
  );
};

export default TransactionCards;

const volumeData = [
  { title: "User wallet payment", num: "1,045" },
  { title: "Group wallet payment", num: "825" },
  { title: "Bill payments", num: "450" },
  { title: "Split transaction requests", num: "150" },
  { title: "SME Transactions", num: "2,450" },
  { title: "SME Requisitions", num: "550" },
];
const byVolume = {
  title: "TOTAL TRANSACTION VOLUME",
  subtitle: "Transaction by volume",
  data: volumeData,
};

const valueData = [
  { title: "User wallet payment", num: "1,000,000,000.00" },
  { title: "Group wallet payment", num: "650,000,000.00" },
  { title: "Bill payments", num: "850,000,000.00" },
  { title: "Split transaction requests", num: "160,000,000.00" },
  { title: "SME Transactions", num: "3,000,000,000.00" },
  { title: "SME Requisitions", num: "45,000,000.00" },
];
const byValue = {
  title: "TOTAL TRANSACTION VALUE",
  subtitle: "Transaction by value",
  data: valueData,
};
const channelData = [
  { title: "Zenith Direct Transfer", num: "1,045" },
  { title: "Offline payment", num: "825" },
  { title: "Card payment", num: "450" },
  { title: "PrimeAirtime/Vendor", num: "150" },
];
const byChannel = {
  title: "TRANSACTION BY CHANNEL",
  subtitle: "Transaction breakdown by channel",
  data: channelData,
};
