import { createQuery } from "./query";
import {
  AllGroupsData,
  CashbackResponse,
  CommissionStats,
  CommissionsResponse,
  DashboardBillPayment,
  DashboardGroupWallet,
  DashboardPersonalWallet,
  EarningByTrans,
  EarningByUser,
  GroupStats,
  GwalaEarningsByFees,
  MessageStats,
  MessagesData,
  TransData,
  TransStats,
} from "./types";

// export const singleUserKey = (arg: string) => ["All", arg];

export const useGetAllTransactions = createQuery<TransData>({
  key: ["/transactions"],
  url: "/transactions",
});
export const useFilterAllTransactions = createQuery<TransData>({
  key: ["/transactions/filter"],
  url: "/transactions/filter",
});

export const useGetTransactionsStats = createQuery<TransStats>({
  key: ["/transactions/allStats"],
  url: "/transactions/allStats",
});
export const useGetEarningsByUser = createQuery<EarningByUser>({
  key: ["/transactions/earnings-by-users"],
  url: "/transactions/earnings-by-users",
});

export const useGetGwalaEarningsByFees = createQuery<GwalaEarningsByFees>({
  key: ["/transactions/gwalaEarningsOnFees"],
  url: "/transactions/gwalaEarningsOnFees",
});

export const useGetEarningsByTrans = createQuery<EarningByTrans>({
  key: ["/transactions/earnings-by-transaction-type"],
  url: "/transactions/earnings-by-transaction-type",
});

export const useGetPersonalWalletTransactionsStats =
  createQuery<DashboardPersonalWallet>({
    key: ["/transactions/personalWalletStats"],
    url: "/transactions/personalWalletStats",
  });

export const useGetGroupWalletTransactionsStats =
  createQuery<DashboardGroupWallet>({
    key: ["/transactions/groupWalletStats"],
    url: "/transactions/groupWalletStats",
  });
export const useGetBillPaymentsStats = createQuery<DashboardBillPayment>({
  key: ["/transactions/billPaymentStats"],
  url: "/transactions/billPaymentStats",
});

export const useGetAllGroups = createQuery<AllGroupsData>({
  key: ["/groups"],
  url: "/groups",
});

export const useGetGroupsStats = createQuery<GroupStats>({
  key: ["/groups/allStats"],
  url: "/groups/allStats",
});

export const useGetAdminMessages = createQuery<MessagesData>({
  key: ["/admin-messages"],
  url: "/admin-messages",
});

export const useGetAdminMessagesAuto = createQuery<MessagesData>({
  key: ["/admin-messages/automated"],
  url: "/admin-messages/automated",
});

export const useGetAdminMessagesStats = createQuery<MessageStats>({
  key: ["/admin-messages/stats"],
  url: "/admin-messages/stats",
});

export const useGetReferralCommissions = createQuery<CommissionsResponse>({
  key: ["/aggregators/users-referral-commission"],
  url: "/aggregators/users-referral-commission",
});

export const useGetBillPaymentCommissions = createQuery<CashbackResponse>({
  key: ["/aggregators/usersBillPaymentCommission"],
  url: "/aggregators/usersBillPaymentCommission",
});

export const useGetCashback = createQuery<CashbackResponse>({
  key: ["/aggregators/cashback-payment"],
  url: "/aggregators/cashback-payment",
});

export const useGetCommissionsStats = createQuery<CommissionStats>({
  key: ["/aggregators/userCommissionStats"],
  url: "/aggregators/userCommissionStats",
});
