import clsx from "classnames";
import { capitalizeText } from "components/utils/string";
import { newDropDownOptions } from "interfaces/transactions";
import { useEffect, useRef, useState } from "react";
import close from "../../../assets/icons/general/close.svg";
import { useFilterAllUsers } from "services/api/path";
import usePersistedState from "hooks/usePersistedState";
import { Allusers } from "services/api/types";
import toast from "react-hot-toast";
import { useUserContext } from "context";
import { getNonEmptyFields } from "utils/functions";
import { Loader2 } from "lucide-react";

export type FetchUsersParams = {
  limit: number;
  page: number;
  period: string;
  startDate: string;
  endDate: string;
  accountActiveStartDate: string;
  accountActiveEndDate: string;
  accountActivePeriod: string;
  verificationStatus?: string;
  user_gender?: string;
  accountStatus?: string;
  minBalance: number;
  maxBalance: number;
};
interface UserFilter {
  visible: boolean;
  onClose: () => void;
  setFilters: React.Dispatch<
    React.SetStateAction<{
      startDate: string;
      endDate: string;
      period: string;
      accountActiveStartDate: string;
      accountActiveEndDate: string;
      accountActivePeriod: string;
      ver_status: string;
      user_gender: string;
      acct_status: string;
    }>
  >;
  setFullQP: React.Dispatch<any>;
}

export default function UserFilter({
  visible,
  onClose,
  setFilters,
  setFullQP
}: UserFilter) {
  const formEmptyState = {
    startDate: "",
    endDate: "",
    period: "",
    accountActiveStartDate: "",
    accountActiveEndDate: "",
    accountActivePeriod: "",
    ver_status: "",
    user_gender: "",
    acct_status: "",
  };
  const balFormEmpty = {
    min_bal: "",
    max_bal: "",
  };
  const selectRef1 = useRef<HTMLSelectElement>(null);
  const selectRef2 = useRef<HTMLSelectElement>(null);
  const [isApplyClicked, setIsApplyClicked] = useState(false);

  const [formState, setFormState] = usePersistedState({
    key: "userFilter",
    defaultValue: formEmptyState,
  });
  const [balFormState, setBalFormState] = usePersistedState({
    key: "balFilter",
    defaultValue: balFormEmpty,
  });

  const {
    setAllUsersFiltered,
    isFilterOn,
    setIsFilterOn,
    pageNumber,
    setPageNumber,
  } = useUserContext();

  const {
    startDate,
    endDate,
    period,
    accountActiveEndDate,
    accountActivePeriod,
    accountActiveStartDate,
  } = formState;
  const fullQueryParams: FetchUsersParams = {
    limit: 20,
    page: pageNumber,
    period: period,
    startDate: startDate,
    endDate: endDate,
    accountActiveStartDate: accountActiveStartDate,
    accountActiveEndDate: accountActiveEndDate,
    accountActivePeriod: accountActivePeriod,
    minBalance: Number(balFormState.min_bal),
    maxBalance: Number(balFormState.max_bal),
  };

  if (formState.ver_status.length > 0) {
    fullQueryParams.verificationStatus =
      formState.ver_status === "verified"
        ? (true as unknown as string)
        : (false as unknown as string);
  }
  if (formState.acct_status.length > 0) {
    fullQueryParams.accountStatus =
      formState.ver_status === "verified"
        ? (true as unknown as string)
        : (false as unknown as string);
  }

  const nonEmptyFields = getNonEmptyFields(fullQueryParams);

  const {
    data: filteredUsers,
    refetch: refetchFilteredUsers,
    isRefetching,
  } = useFilterAllUsers({
    queryParams: { ...nonEmptyFields, limit : 20 },
  });

  const handleFiltering = () => {
    // setBalFormState(balFormEmpty)
    setFullQP(fullQueryParams)
    setIsApplyClicked(true);
    setIsFilterOn(true);
    refetchFilteredUsers();
    setFilters(formState);
  };

  useEffect(() => {
    if (!isRefetching && isFilterOn && isApplyClicked) {
      // setAllUsersData(filteredUsers);
      setAllUsersFiltered(filteredUsers);
      toast.success("Filters applied");
      setIsApplyClicked(false);
      onClose();
    }
  }, [refetchFilteredUsers, isRefetching]);

  useEffect(() => {
    setPageNumber(1);
  }, []);
  return (
    <div
      role="presentation"
      onClick={onClose}
      className={clsx(
        "fixed top-0 left-0 z-[999] w-full h-screen opacity-0 pointer-events-none overflow-y-auto bg-black/[.6] flex items-center justify-center",
        {
          "!opacity-100 !pointer-events-auto": visible,
        }
      )}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white pt-8 py-[72px] px-[31px] pb-[50px] xl:w-[1281px] flex flex-col"
      >
        <div className="">
          <div className="flex items-center justify-between">
            <h1 className="text-lg font-bold mb-4">Filter users</h1>

            <span
              className="p-3 bg-D9D9D9 rounded-full cursor-pointer"
              onClick={onClose}
            >
              <img src={close} alt="" />
            </span>
          </div>
          <div className="flex gap-11 border-b border-[#D9D9D9] pb-8">
            <div className="">
              <div className="">
                <h1 className="text-lg font-bold mb-2">
                  Custom registration date
                </h1>
                <div className="flex gap-4 items-end">
                  <label htmlFor="startDate">
                    <p>Start date</p>

                    <input
                      className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                      placeholder="Select start date"
                      type="date"
                      name="startDate"
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          startDate: e.target.value,
                          period: "",
                        })
                      }
                      value={formState.startDate}
                    />
                  </label>
                  <p className="h-8">-</p>
                  <label htmlFor="end_date">
                    <p>End date</p>
                    <input
                      className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                      placeholder="Select end date"
                      type="date"
                      name="end_date"
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          endDate: e.target.value,
                          period: "",
                        })
                      }
                      value={formState.endDate}
                    />
                  </label>
                  <select
                    ref={selectRef1}
                    // value={selectedOption}
                    className="bg-[#F5F5F5] p-2 rounded-md h-10"
                    value={formState.period}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        period: e.target.value,
                        startDate: "",
                        endDate: "",
                      })
                    }
                    name="period"
                  >
                    <option>Select date</option>
                    {newDropDownOptions.map((option, index) => (
                      <option value={option.value} key={index}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mt-3">
                <h1 className="text-lg font-bold mb-2">
                  Account - days last active
                </h1>
                <div className="flex gap-4 items-end">
                  <label htmlFor="accountActiveStartDate">
                    <p>Start date</p>

                    <input
                      className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                      placeholder="Select start date"
                      type="date"
                      name="accountActiveStartDate"
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          accountActiveStartDate: e.target.value,
                          accountActivePeriod: "",
                        })
                      }
                      value={formState.accountActiveStartDate}
                    />
                  </label>
                  <p className="h-8">-</p>
                  <label htmlFor="end_date">
                    <p>End date</p>
                    <input
                      className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                      placeholder="Select end date"
                      type="date"
                      name="accountActiveEndDate"
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          accountActiveEndDate: e.target.value,
                          accountActivePeriod: "",
                        })
                      }
                      value={formState.accountActiveEndDate}
                    />
                  </label>
                  <select
                    ref={selectRef2}
                    // value={selectedOption}
                    className="bg-[#F5F5F5] p-2 rounded-md h-10"
                    value={formState.accountActivePeriod}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        accountActivePeriod: e.target.value,
                        accountActiveStartDate: "",
                        accountActiveEndDate: "",
                      })
                    }
                    name="accountActivePeriod"
                  >
                    <option>Select date</option>
                    {newDropDownOptions.map((option, index) => (
                      <option value={option.value} key={index}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-5 gap-y-3">
              <div className="mt-6">
                <h1 className="text-lg font-bold mb-2">Verification Status</h1>
                <div className="flex gap-3 flex-wrap">
                  {ver_status.map((type) => (
                    <button
                      className="w-[150px] py-2 rounded-md disabled:opacity-40 h-10"
                      style={{
                        backgroundColor: `${
                          formState.ver_status === type.tag
                            ? "#273B4A"
                            : "#F5F5F5"
                        }`,
                        color: `${
                          formState.ver_status === type.tag ? "#FFF" : "#000000"
                        }`,
                      }}
                      onClick={() =>
                        setFormState({
                          ...formState,
                          ver_status:
                            formState.ver_status === type.tag ? "" : type.tag,
                        })
                      }
                    >
                      {type.name}
                    </button>
                  ))}
                </div>
              </div>
              {/* <div className="mt-6">
                <h1 className="text-lg font-bold mb-2">User Gender</h1>
                <div className="flex gap-3 flex-wrap">
                  {user_gender.map((status) => (
                    <button
                      className="w-[150px] py-2 rounded-md disabled:opacity-40 h-10"
                      style={{
                        backgroundColor: `${
                          formState.user_gender === status
                            ? "#273B4A"
                            : "#F5F5F5"
                        }`,
                        color: `${
                          formState.user_gender === status ? "#FFF" : "#000000"
                        }`,
                      }}
                      onClick={() =>
                        setFormState({ ...formState, user_gender: status })
                      }
                    >
                      {capitalizeText(status)}
                    </button>
                  ))}
                </div>
              </div> */}
              <div className="mt-6">
                <h1 className="text-lg font-bold mb-2">User account status</h1>
                <div className="flex gap-3 flex-wrap">
                  {acct_status.map((status) => (
                    <button
                      className="w-[150px] py-2 rounded-md disabled:opacity-40 h-10"
                      style={{
                        backgroundColor: `${
                          formState.acct_status === status
                            ? "#273B4A"
                            : "#F5F5F5"
                        }`,
                        color: `${
                          formState.acct_status === status ? "#FFF" : "#000000"
                        }`,
                      }}
                      onClick={() =>
                        setFormState({
                          ...formState,
                          acct_status:
                            formState.acct_status === status ? "" : status,
                        })
                      }
                    >
                      {capitalizeText(status)}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className=" mt-6">
              <h1 className="text-lg font-bold mb-2">User balance filter</h1>
              <div className="flex gap-4 items-center">
                <input
                  className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                  placeholder="Enter min balance"
                  type="number"
                  name="min_bal"
                  value={balFormState.min_bal}
                  onChange={(e) =>
                    setBalFormState({
                      ...balFormState,
                      min_bal: e.target.value,
                    })
                  }
                />
                -
                <input
                  className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                  placeholder="Enter max balance"
                  type="number"
                  name="max_bal"
                  value={balFormState.max_bal}
                  onChange={(e) =>
                    setBalFormState({
                      ...balFormState,
                      max_bal: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-3">
            <button
              className="ml-auto border border-[#273B4A] rounded-md py-2 px-4 hover:bg-[#273B4A] hover:text-white"
              onClick={() => {
                setFormState(formEmptyState);
                // refetchFilteredUsers();
                setIsFilterOn(false);
                setFilters(formState);
                window.localStorage.removeItem("userFilter");
                onClose();
              }}
            >
              Clear filters
            </button>
            <button
              className=" border border-[#273B4A] rounded-md py-2 px-4 hover:bg-[#273B4A] hover:text-white disabled:opacity-30 flex gap-3 justify-center items-center"
              onClick={handleFiltering}
              disabled={isRefetching && isApplyClicked}
            >
              Apply filters
              {isRefetching && isApplyClicked && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const ver_status = [
  {
    name: "Verified",
    tag: "verified",
  },
  {
    name: "Unverified",
    tag: "unverfied",
  },
];
const user_gender = ["male", "female"];
const acct_status = ["active", "blocked"];
