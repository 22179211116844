import { useEffect, useState } from "react";
import { useGetSingleUserReferees } from "services/api/path";
import { CommaSeperatedNumber, PlainButton, Table } from "components/general";
import { TableColumn } from "react-data-table-component";
import { RefereeData } from "services/api/types";
import { format } from "date-fns";
import ProgressBar from "components/ProgressBar";
import PaginationTab from "components/general/PaginationBar";
import { useAuthContext } from "context";
import { handleExportDownload } from "utils/functions";

type RefereeProps = {
  email: string;
};

export const UserReferees = ({ email }: RefereeProps) => {
  const [page, setPage] = useState(1);
  const [isExporting, setIsExporting] = useState(false);
  const { authToken } = useAuthContext();

  const {
    data: userReferees,
    isLoading: isRefereesLoading,
    refetch: refetchReferees,
    isRefetching,
  } = useGetSingleUserReferees({
    path: email as string,
    queryParams: {
      page: page,
      limit: 20,
    },
  });

  const handleExport = async () => {
    setIsExporting(true);
    await handleExportDownload(
      `users/export-user-referees/${email}`,
      `all-${email}-referees`,
      authToken!
    );
    setIsExporting(false);
  };

  const columns: TableColumn<RefereeData>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      cell: (row, index) => (
        <>
          {userReferees?.meta.currentPage
            ? index + 1 + 10 * (userReferees.meta.currentPage - 1)
            : ""}
        </>
      ),
      width: "70px",
    },
    {
      name: "Username",
      cell: (row) => <div className="text-black">{row.fullName}</div>,
      sortable: false,
    },
    {
      name: "email address",
      cell: (row) => <div className="text-black">{row.email}</div>,
      sortable: false,
    },
    {
      name: "Commission Earned",
      // width: '175px',
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A `}
          value={row.commission}
        />
      ),
    },
    {
      name: "Date Activated",
      selector: (row) => row.dateActivated,
      sortable: true,
      cell: (row) => format(new Date(row.dateActivated), "dd/MM/yyyy"),
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    refetchReferees();
  }, [page, refetchReferees]);

  if (isRefereesLoading) return <ProgressBar />;
  return (
    <div className="flex flex-col space-y-6">
      <div className="flex justify-end">
        <PlainButton
          title="Export"
          onClick={handleExport}
          disabled={isExporting}
          isLoading={isExporting}
        />
      </div>
      <div className={`${isRefetching ? "opacity-30" : ""}`}>
        <Table
          data={userReferees?.content!}
          columns={columns}
          noDataComponentDescription="All your referrals will be listed here"
          noDataComponentHeader="No Referees yet"
        />
      </div>
      <div className="mb-6">
        <PaginationTab
          currentPage={page}
          setCurrentPage={setPage}
          totalPages={userReferees?.meta.totalPages!}
        />
      </div>
    </div>
  );
};
