import StatsCard from "components/general/StatsCard";
import { Wrapper } from "components/navigation";
import React, { useMemo, useState } from "react";
import clsx from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetSingleAggDetails,
  useGetSingleAggStats,
} from "services/api/path";
import { routes } from "utils/constants";
import AggregatorWalletTrans from "components/aggregators/AggregatorWalletTrans";
import ProgressBar from "components/ProgressBar";
import AggregatorBio from "components/aggregators/AggregatorBio";
import AggregatorLog from "components/aggregators/AggregatorLogs";
import PayoutsToSelf from "components/aggregators/PayoutsToSelf";
import PayoutsToAgents from "components/aggregators/PayoutsToAgents";

const TABS = [
  { name: "AGENT TRANSACTIONS", id: "agent_transactions" },
  { name: "AGENT LOG", id: "agent_log" },
  { name: "PAYOUTS TO SELF", id: "payouts_to_self" },
  { name: "PAYOUTS TO AGENT", id: "payouts_to_agents" },
  { name: "DETAILS", id: "details" },
];

export default function AggregatorDetail() {
  const navigate = useNavigate();
  const { email } = useParams();
  const [activeTab, setActiveTab] = useState(TABS[0].id);


  const { data: singleAggregator, isLoading: isSingleAggLoading } =
    useGetSingleAggDetails({
      path: email as string,
    });
  const { data: singleAggStats, isLoading } = useGetSingleAggStats({
    path: email as string,
  });
   const singleAggStatsData = useMemo(
     () =>
       singleAggStats ?? {
         totalEarnings: 0,
         unpaidCommission: 0,
         totalSubAgents: 0,
       },
     [singleAggStats]
   );

   if (isSingleAggLoading || isLoading) return <Wrapper><ProgressBar/></Wrapper>

  return (
    <Wrapper>
      <div className="mt-[18px]">
        <span className="font-productsans text-273B4A text-base">
          <span
            onClick={() => navigate(routes.aggregators)}
            className="cursor-pointer"
          >
            All aggregators
          </span>{" "}
          {">"}{" "}
          <span className="text-BBB5B5">{singleAggregator?.fullname}</span>
        </span>
        <div className="mt-[13px] flex gap-3">
          <StatsCard
            header="TOTAL EARNINGS"
            num={singleAggStatsData.totalEarnings!}
            decimal={true}
            name="totalEarnings"
            width={255}
            handleSelect={() => console.log("here")}
          />
          <StatsCard
            header="UNPAID AGENT COMMISSION"
            num={singleAggStatsData.unpaidCommission!}
            decimal={true}
            name="unpaidAgentCommission"
            width={245}
            handleSelect={() => console.log("here")}
          />
          <StatsCard
            header="TOTAL AGENTS"
            num={singleAggStatsData.totalSubAgents!}
            decimal={false}
            name="totalAgents"
            handleSelect={() => console.log("here")}
            width={245}
          />
        </div>
        <div className="mt-[35px] flex space-x-10 items-center">
          <div className="flex space-x-7 items-center">
            {TABS.map((tab) => (
              <button
                onClick={() => setActiveTab(tab.id)}
                className={clsx(
                  "text-BBB5B5 transition-all font-productsans text-base border-b-[4px] border-b-transparent",
                  {
                    "!border-b-273B4A font-productsans700 !text-273B4A":
                      activeTab === tab.id,
                  }
                )}
              >
                {tab.name}
              </button>
            ))}
          </div>
        </div>
        <div className="mt-[45px]">
          {/* content */}
          {activeTab === "agent_transactions" && (
            <AggregatorWalletTrans email={email!} />
          )}
          {activeTab === "details" && (
            <AggregatorBio userDetails={singleAggregator!} />
          )}
          {activeTab === "agent_log" && <AggregatorLog email={email!} />}
          {activeTab === "payouts_to_self" && <PayoutsToSelf email={email!} />}
          {activeTab === "payouts_to_agents" && <PayoutsToAgents email={email!} />}
          {/* {activeTab === "agent_transactions" ? (
            <AggregatorWalletTrans email={email!} />
          ) : activeTab === "agent_log" ? (
            <UserPendingTransactions email={email!} />
          ) : activeTab === "payouts_to_self" ? (
            <UserPurchases />
          ) : activeTab === "payouts_to_agents" ? (
            <UserBio userDetails={singleAggregator!} />
          ) : 
            <UserBio userDetails={singleAggregator!} />
          } */}
        </div>
      </div>
    </Wrapper>
  );
}
