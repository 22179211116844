import React from 'react';
import DataTable, {
	TableColumn,
	TableStyles,
	PaginationComponentProps,
} from 'react-data-table-component';
import { ReactComponent as Zombie } from 'assets/images/zombie.svg';

interface TableProps<T> {
	columns: TableColumn<T>[];
	data: T[];
	noDataComponentHeader?: string;
	noDataComponentDescription?: string;
	handleSelectedRowsChange?: (_: any) => void;
}


const customStyles: TableStyles = {
	head: {
		style: {
			color: '273B4A',
			fontSize: '16px',
			fontFamily: 'productsans-700',
			textTransform: 'uppercase',
		},
	},
	headRow: {
		style: {
			minHeight: '64px',
		},
	},
	headCells: {
		style: {
			'>div>div': {
				whiteSpace: 'normal',
				wordWrap: 'break-word',
			},
			borderRight: '1px solid #BBB5B5',
			borderBottom: '1px solid #BBB5B5',
			borderTop: '1px solid #BBB5B5',
			'&:last-of-type': {
				borderRight: '1px solid transparent',
			},
		},
	},
	rows: {
		style: {
			fontFamily: 'productsans',
			fontSize: '16px',
			fontWeight: 400,
			color: '#273B4A',
			borderBottomStyle: 'solid',
			borderBottomWidth: '1px',
			borderBottomColor: '#BBB5B5',
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#BBB5B5',
			},
		},
	},
	cells: {
		style: {
			paddingTop: '16px',
			paddingBottom: '16px',
			paddingLeft: '20px',
			paddingRight: '20px',
			borderRight: '1px solid #BBB5B5',
			'&:last-of-type': {
				borderRight: '1px solid transparent',
			},
		},
	},
};

const PAGE_ROWS = 20;

const Pagination = (props: PaginationComponentProps) => {
	const { rowCount} = props;
	const totalPages = Math.ceil(rowCount / PAGE_ROWS);
	const startIndex = 1;
	const endIndex = Math.min(startIndex + PAGE_ROWS, totalPages);

	return (
		<div className="hidden">
			{/* showing {(currentPage * rowCount) - rowCount + 1} - {currentPage * rowCount} of {totalPages * rowCount}
			<div className="ml-auto flex space-x-[10px]">
				{Array.from({ length: endIndex - startIndex + 1 }, (_, index) => (
					<button
						onClick={() => onChangePage(startIndex + index, 0)}
						key={startIndex + index}
						className={clsx(
							'bg-white h-[42px] w-[46px] border border-D9D9D9 rounded-[10px] text-273B4A flex items-center justify-center',
							{ '!bg-D9D9D9': currentPage === startIndex + index }
						)}>
						{startIndex + index}
					</button>
				))}
			</div>		 */}
			{endIndex}
		</div>
	);
};

const NoDataComponent = (header?: string, description?: string) => {
	return (
		<div className="py-[100px] flex items-center justify-center">
			<div className="flex flex-col space-y-[44.62px] items-center justify-center">
				<Zombie />
				<div className="flex flex-col space-y-5">
					<h3 className="font-productsans700 text-273B4A text-center text-[24px]">
						{header ?? 'No data'}
					</h3>
					{description && (
						<p className="text-center max-w-[371px] text-273B4A text-[21px] font-productsans">
							{description}
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

const Table = ({
	columns,
	data,
	noDataComponentHeader,
	noDataComponentDescription,
	handleSelectedRowsChange,
}: TableProps<any>) => {
	return (
		<DataTable
			columns={columns}
			data={data}
			selectableRows
			selectableRowsHighlight
			customStyles={customStyles}
			onSelectedRowsChange={handleSelectedRowsChange}
			pagination
			// rows
			paginationComponent={Pagination}
			paginationPerPage={PAGE_ROWS}
			noDataComponent={NoDataComponent(
				noDataComponentHeader,
				noDataComponentDescription
			)}
		/>
	);
};

export default Table;
