import { CommaSeperatedNumber, PlainButton, Table } from "components/general";
import { Wrapper } from "components/navigation";
// import React from 'react';
import moment from "moment";
import { TableColumn } from "react-data-table-component";
import { GwalaUser2 } from "interfaces/users";
import { NA, routes } from "utils/constants";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { SendMessageModal } from "components/users";
import { newDropDownOptions } from "interfaces/transactions";
import React, { useState, useEffect } from "react";
import {
  useFilterAllUsers,
  useGetAllUsers,
  useGetGeneralStats,
} from "services/api/path";
import ProgressBar from "components/ProgressBar";
import { useAuthContext, useUserContext } from "context";
import { debounce } from "lodash";
import StatsCard from "components/general/StatsCard";
import PaginationTab from "components/general/PaginationBar";
import {
  getNonEmptyFields,
  handleExportDownload,
  pickFirstTwoNames,
} from "utils/functions";
import UserFilter from "components/general/FilterModals/userFilter";

const UserList = () => {
  const navigate = useNavigate();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [query, setQuery] = React.useState("");
  const [openFilter, setOpenFilter] = React.useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const {
    setSelectedUser,
    setAllUsersFiltered,
    setPageNumber,
    pageNumber,
    allUsersFiltered,
    isFilterOn,
    setIsFilterOn
  } = useUserContext();
  const { authToken } = useAuthContext();
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [userVerified, setUserVerified] = useState("verifiedUsers");
  const [usersStatsParams, setUsersStatsParams] = useState({
    totalRegisteredUsers: "allTime",
    totalActiveUsers: "allTime",
  });
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    period: "",
    accountActiveStartDate: "",
    accountActiveEndDate: "",
    accountActivePeriod: "",
    ver_status: "",
    user_gender: "",
    acct_status: "",
  });
  const [fullQP, setFullQP] = useState<any>();
  const nonEmptyFieldsParams = getNonEmptyFields(fullQP);

  const {
    data: allUsers,
    isLoading,
    refetch: refetchUsers,
    isRefetching: isRefetchingUsers,
  } = useGetAllUsers({
    queryParams: {
      page: pageNumber,
      limit: 20,
      filter: query,
    },
    onSuccess: () => {
      if (!isFilterOn) {
        setAllUsersFiltered(allUsers);
      }
    },
  });

  const {
    data: genStats,
    isLoading: isLoadingStats,
    refetch: refetchStats,
    isRefetching: isRefetchingStats,
  } = useGetGeneralStats({
    queryParams: usersStatsParams,
  });

  const {
    data: filteredUsers,
    refetch: refetchFilteredUsers,
    isRefetching,
  } = useFilterAllUsers({
    queryParams: { ...nonEmptyFieldsParams, page: pageNumber, limit : 20 },
    onSuccess: () => {
      if(isFilterOn) {
      setAllUsersFiltered(filteredUsers);

      }
    },
  });
  const handleParamsChange = (val: string, name: string) => {
    if (name === "totalRegisteredUsers" || name === "totalActiveUsers") {
      setUsersStatsParams({
        ...usersStatsParams,
        [name]: val,
      });
    } else {
      setUserVerified(val);
    }
  };
  useEffect(() => {
    if (isFilterOn) {     
      setQuery("");
      refetchFilteredUsers();
    } else {
      refetchUsers();
    }
    window.scrollTo(0, 0);
  }, [pageNumber, refetchUsers]);

  useEffect(() => {
    refetchStats();
  }, [usersStatsParams, refetchStats]);

  const [nonEmptyFields, setNonEmptyFields] = useState(
    Object.entries(filters!).filter(([key, value]) => value !== "")
  );
  // const nonEmptyFields =

  //=======making a query search after every 3 seconds provided there's a searched query======//
  const debouncedSearch = React.useRef(
    debounce(() => {
      refetchUsers();
    }, 300)
  ).current;

  useEffect(() => {
    setPageNumber(1);

    if (query) {
      setPageNumber(1);
      setIsFilterOn(false)
      debouncedSearch();
    } else if (query.length === 0) {
      setPageNumber(1);
      refetchUsers();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch, refetchUsers]);
  //==================================================================

  const columns: TableColumn<GwalaUser2>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      sortable: false,
      cell: (row, index) => (
        <>
          {allUsersFiltered?.meta.currentPage
            ? index + 1 + 20 * (allUsersFiltered?.meta.currentPage! - 1)
            : ""}
        </>
      ),
      width: "70px",
    },
    {
      name: "USER FULL NAME",
      selector: (row) => row.fullName,
    },
    {
      name: "VERIFICATION STATUS",
      width: "16%",
      cell: (row) => (
        <div className="mx-auto">
          {row.isVerified ? (
            <div className="bg-E5FDD1 h-5 flex items-center justify-center px-3 rounded-50">
              <span className="font-productsans text-[12px] text-273B4A whitespace-nowrap">
                Account Verified
              </span>
            </div>
          ) : (
            <div className="bg-FFACC7 h-5 flex items-center justify-center px-3 rounded-50">
              <span className="font-productsans text-[12px] text-273B4A whitespace-nowrap">
                Pending Verification
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Referral code",
      width: "130px",
      cell: (row) => (
        <span className="font-productsans text-base text-273B4A mx-auto">
          {row.fromReferralCode?.length > 0 ? row.fromReferralCode : NA}
        </span>
      ),
    },
    {
      name: "Referral name",
      width: "130px",
      cell: (row) => (
        <span className="font-productsans text-base text-273B4A">
          {row.referredBy?.length > 0 ? pickFirstTwoNames(row.referredBy) : NA}
        </span>
      ),
    },
    {
      name: "LAST LOGIN",
      width: "130px",
      cell: (row) => (
        <span className="font-productsans text-base text-273B4A">
          {row.createdAt ? moment(row.createdAt).format("DD/MM/YY") : NA}
        </span>
      ),
    },
    {
      name: "WALLET BALANCE",
      width: "10%",
      selector: (row) => row.walletAmount || 0,
      cell: (row) =>
        !row.walletAmount && row.walletAmount !== 0 ? (
          <span className="font-productsans text-base text-273B4A">{NA}</span>
        ) : (
          <CommaSeperatedNumber
            value={row.walletAmount}
            className="text-273B4A text-base font-productsans"
          />
        ),
    },
    {
      name: "PENDING TRANSACTIONS",
      width: "12.5%",
      selector: (row) => row.pendingTransaction || 0,
      cell: (row) =>
        row.pendingTransaction === null ? (
          <span className="font-productsans text-base text-273B4A">{NA}</span>
        ) : (
          <CommaSeperatedNumber
            value={row.pendingTransaction}
            className="text-FF0053 text-base font-productsans"
          />
        ),
    },
    {
      name: "ACTION",
      cell: (row) => (
        <button
          onClick={() => {
            setSelectedUser(row);
            navigate(routes.getUserDetailRoute(row.email));
          }}
          disabled={!row.isVerified}
          className="mx-auto text-0067B4 font-productsans700 text-base disabled:opacity-30 cursor-pointer disabled:cursor-not-allowed"
        >
          View details
        </button>
      ),
    },
  ];

  const handleSelectedRowsChange = (x: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => {
    setSelectedRows(x.selectedRows);
  };

  const onSendMessageClick = () => {
    if (isEmpty(selectedRows)) {
      toast.error("Please select a user/users to send messages to");
      return;
    }
    setShowMessageModal(true);
  };
  const handleExport = async () => {
    setIsExporting(true);
    await handleExportDownload("users/export", "all-users", authToken!);
    setIsExporting(false);
  };

  useEffect(() => {
    setNonEmptyFields(
      Object.entries(filters!).filter(([key, value]) => value !== "")
    );
  }, [filters, isFilterOn]);

  useEffect(() => {
    if (!isFilterOn) {
      setAllUsersFiltered(allUsers);
    } else {
      setQuery("");
      setAllUsersFiltered(filteredUsers);
    }
  }, [isLoading, allUsers, isFilterOn, filteredUsers, pageNumber]);

  if (isLoading || isLoadingStats || allUsersFiltered == undefined)
    return (
      <Wrapper>
        <ProgressBar />
      </Wrapper>
    );

  return (
    <Wrapper>
      <SendMessageModal
        visible={showMessageModal}
        onClose={() => setShowMessageModal(false)}
        recipients={selectedRows}
        category="users"
      />
      {openFilter && (
        <UserFilter
          onClose={() => setOpenFilter(false)}
          visible={openFilter}
          setFilters={setFilters}
          setFullQP={setFullQP}
        />
      )}
      <div
        className={`flex gap-[14px] flex-wrap ${
          isRefetchingStats ? "opacity-30" : ""
        }`}
      >
        <StatsCard
          header="TOTAL REGISTERED USERS"
          dropDownOptions={newDropDownOptions}
          num={genStats?.totalRegisteredUsers.value!}
          text={usersStatsParams.totalRegisteredUsers}
          percent={genStats?.totalRegisteredUsers.change.toFixed(2)!}
          decimal={false}
          name="totalRegisteredUsers"
          handleSelect={handleParamsChange}
        />

        <StatsCard
          header="TOTAL ACTIVE USERS"
          dropDownOptions={newDropDownOptions}
          num={genStats?.totalActiveUsers.value!}
          text={usersStatsParams.totalActiveUsers}
          percent={
            genStats?.totalActiveUsers.change
              ? genStats?.totalActiveUsers.change.toFixed(2)!
              : "0"
          }
          name="totalActiveUsers"
          decimal={false}
          width={312}
          handleSelect={handleParamsChange}
        />
        <StatsCard
          header={`${
            userVerified === "unverifiedUsers" ? "UN" : ""
          }VERIFIED USERS`}
          dropDownOptions={[
            { name: "Verified users", value: "verifiedUsers" },
            { name: "Unverified users", value: "unverifiedUsers" },
          ]}
          num={
            userVerified === "verifiedUsers"
              ? genStats?.totalVerifiedUsers!
              : genStats?.totalUnverifiedUsers!
          }
          width={312}
          decimal={false}
          name="checkVerification"
          handleSelect={handleParamsChange}
        />
      </div>
      <div className="mt-[59px] flex flex-col space-y-[25px]">
        <div className="flex flex-col md:flex-row items-center gap-4 justify-between px-3">
          <input
            className="w-full md:w-1/2 border border-D9D9D9 rounded-5 px-4 truncate outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
            placeholder="Search user by full name, email address or phone number"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="flex gap-3 flex-wrap">
            <PlainButton
              title="Filter records"
              onClick={() => setOpenFilter(true)}
            />
            {/* <PlainButton title="Create group" /> */}
            <PlainButton title="Send message" onClick={onSendMessageClick} />
            <PlainButton
              title="Export"
              onClick={handleExport}
              disabled={isExporting}
              isLoading={isExporting}
            />
          </div>
        </div>
        <div className="">
          {nonEmptyFields.length > 0 && isFilterOn && (
            <>
              <h1>Selected filters</h1>
              <div className="flex gap-2 flex-wrap">
                {nonEmptyFields.map(([key, value]) => (
                  <p key={key}>
                    <strong>{key}:</strong> {value}
                  </p>
                ))}
              </div>
            </>
          )}
        </div>
        <div className={`${isRefetchingUsers || isRefetching ? "opacity-30" : ""} space-y-8`}>
          <Table
            columns={columns}
            data={allUsersFiltered?.content!}
            handleSelectedRowsChange={handleSelectedRowsChange}
          />
        </div>
        <div className="mb-6">
          <PaginationTab
            currentPage={pageNumber}
            setCurrentPage={setPageNumber}
            totalPages={allUsersFiltered?.meta.totalPages!}
          />{" "}
        </div>
      </div>
    </Wrapper>
  );
};

export default UserList;
