import StatsCard from "components/general/StatsCard";
import { Wrapper } from "components/navigation";
import React, { useEffect, useState } from "react";
import clsx from "classnames";

import {
  useGetCashback,
  useGetBillPaymentCommissions,
  useGetCommissionsStats,
  useGetReferralCommissions,
} from "services/api/transactionPaths";

import ProgressBar from "components/ProgressBar";
import { CommissionsTable } from "./CommisionsTable";
import { CashbackTable } from "./CashbackTable";
import { BillsComissionTable } from "./BillsCommissionsTable";

export const Commissions = () => {
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(TABS[0].id);

  const {
    data: commissions,
    isLoading: isCommissionsLoading,
    refetch: refetchCommissions,
    isRefetching,
  } = useGetReferralCommissions({
    queryParams: {
      page: page,
      limit: 20,
    },
  });

  const {
    data: cashbacks,
    isLoading: isCashbackLoading,
    refetch: refetchCashback,
    isRefetching : isRefetchingCashback,
  } = useGetCashback({
    queryParams: {
      page: page,
      limit: 20,
    },
  });

  const {
    data: billsCommisions,
    isLoading: isBillsCommsissionsLoading,
    refetch: refetchBillsCommissions,
    isRefetching: isRefetchingBillsCommissions,
  } = useGetBillPaymentCommissions({
    queryParams: {
      page: page,
      limit: 20,
    },
  });

  const {
    data: commissionStats,
    isLoading: isLoadingStats,
  } = useGetCommissionsStats({});

  useEffect(() => {
    refetchCashback();
    refetchCommissions();
    refetchBillsCommissions();
    window.scrollTo(0, 0);
  }, [page, refetchCashback, refetchCommissions, refetchBillsCommissions]);

  if (isLoadingStats || isCommissionsLoading || isCashbackLoading || isBillsCommsissionsLoading ) return (
    <Wrapper>
      <ProgressBar />
    </Wrapper>
  );
  return (
    <Wrapper>
      <div className="flex gap-4 mt-5">
        <StatsCard
          header="TOTAL CASHBACK AMOUNT"
          num={commissionStats?.cashbackAmount!}
          width={312}
          decimal={true}
          name="messagesSent"
          handleSelect={() => console.log("here")}
        />
        <StatsCard
          header="TOTAL COMMISSIONS"
          num={commissionStats?.commissionAmount!}
          width={312}
          handleSelect={() => console.log("here")}
          name="totalMessageGroups"
          decimal={true}
        />
      </div>
      <div className="mt-[35px] flex space-x-10 items-center">
        <div className="flex space-x-7 items-center">
          {TABS.map((tab) => (
            <button
              onClick={() => setActiveTab(tab.id)}
              className={clsx(
                "text-BBB5B5 transition-all font-productsans text-base border-b-[4px] border-b-transparent",
                {
                  "!border-b-273B4A font-productsans700 !text-273B4A":
                    activeTab === tab.id,
                }
              )}
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>
      {activeTab === "commissions" ? (
        <CommissionsTable
          commissions={commissions!}
          page={page}
          setPage={setPage}
          isRefetching={isRefetching}
        />
      ) : activeTab === "bill_commissions" ? <BillsComissionTable billsCommision={billsCommisions!} isRefetchingCashback={isRefetchingBillsCommissions} page={page} setPage={setPage} /> : (
        <CashbackTable
          cashbacks={cashbacks!}
          page={page}
          setPage={setPage}
          isRefetchingCashback={isRefetchingCashback}
        />
      )}
    </Wrapper>
  );
};

const TABS = [
  { name: "CASHBACK PAYMENT", id: "cashback_payment" },
  { name: "REFERRAL COMMISSIONS", id: "commissions" },
  { name: "BILL PAYMENT COMMISSIONS", id: "bill_commissions" },
];
