import { AggregatorContextProvider } from "context/aggregator";
import { DashboardContextProvider } from "context/dashboard";
import { TransContextProvider } from "context/transaction";
import { UserContextProvider } from "context/user";
import { AggregatorDetail, AggregatorList } from "pages/aggregators";
import { Login } from "pages/auth";
import { Commissions } from "pages/commissions";
import DashboardPage from "pages/dashboard";
import GroupPage from "pages/groups/groupPage";
import Groups from "pages/groups/totalGroups";
import Messages from "pages/messages/messages";
import TransactionPage from "pages/transaction";
import TransactionTable from "pages/transaction/transcationTable";
import { UserDetail, UserList } from "pages/users";
import React from "react";
import { createBrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
import IsLoggedIn from "services/isLoggedIn";
import { routes } from "utils/constants";

const GwalaRoutes: React.FC = () => {
  const router = createBrowserRouter([
    { path: routes.login, element: <Login /> },
    { path: routes.users, element: <UserList /> },
    { path: routes.transHistory, element: <Messages /> },
    { path: routes.transactions, element: <TransactionTable /> },
    { path: routes.groups, element: <Groups /> },
    { path: routes.user_detail, element: <UserDetail /> },
  ]);

  // return <RouterProvider router={router}></RouterProvider>;
  return (
    <Routes>
      <Route path={routes.login} element={<Login />} />
      <Route
        path={routes.dashboard}
        element={
          <IsLoggedIn
            component={
              <DashboardContextProvider>
                <DashboardPage />
              </DashboardContextProvider>
            }
          />
        }
      />
      <Route
        path={routes.users}
        element={
          <IsLoggedIn
            component={
              <UserContextProvider>
                <UserList />
              </UserContextProvider>
            }
          />
        }
      />
      <Route
        path={routes.transHistory}
        element={<IsLoggedIn component={<Messages />} />}
      />
      <Route
        path={routes.commissions}
        element={<IsLoggedIn component={<Commissions />} />}
      />
      <Route
        path={routes.transactions}
        element={
          <IsLoggedIn
            component={
              <TransContextProvider>
                <TransactionPage />
              </TransContextProvider>
            }
          />
        }
      />
      <Route
        path={routes.groups}
        element={<IsLoggedIn component={<GroupPage />} />}
      />
      <Route
        path={routes.user_detail}
        element={
          <IsLoggedIn
            component={
              <UserContextProvider>
                <UserDetail />
              </UserContextProvider>
            }
          />
        }
      />
      <Route
        path={routes.aggregators}
        element={
          <IsLoggedIn
            component={
              <AggregatorContextProvider>
                <AggregatorList />
              </AggregatorContextProvider>
            }
          />
        }
      />
      <Route
        path={routes.aggregator_detail}
        element={
          <IsLoggedIn
            component={
              <AggregatorContextProvider>
                <AggregatorDetail />
              </AggregatorContextProvider>
            }
          />
        }
      />
    </Routes>
  );
};

export default GwalaRoutes;
