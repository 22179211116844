export const capitalizeText = (text?: string) => {
  if (!text) return;

  const words = text.split(" ");

  const capitalizedWords = words.map((word) => {
    const lowercaseWord = word.toLowerCase();
    return (
      lowercaseWord[0].toUpperCase() +
      lowercaseWord.slice(1, lowercaseWord.length)
    );
  });

  return capitalizedWords.join(" ");
};
