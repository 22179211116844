import React, { useRef, useState } from "react";
import { ReactComponent as Dropdown } from "assets/icons/general/dropdown2.svg";
import { newDropDownOptions } from "interfaces/transactions";

interface StyledSelectProps {
  options: { name: string; value: string }[];
  handleSelect: (val: string, name: string) => void;
  active: boolean;
  name: string;
}

const StyledSelect = ({
  options,
  handleSelect,
  active,
  name,
}: StyledSelectProps) => {
  const [selectedOption, setSelectedOption] = useState(options[0].name);
  const selectRef = useRef<HTMLSelectElement>(null);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    for (let i = 0; i < newDropDownOptions.length; i++) {
      const element = newDropDownOptions[i];
      if (element.value === value) {
        setSelectedOption(element.name);
      }
    }
    handleSelect(value, name);
  };

  return (
    <div className="relative">
      <div
        role="presentation"
        onClick={() => selectRef?.current?.click()}
        className={`absolute transition-all pointer-events-none h-5 rounded-[50px] px-2 cursor-pointer flex items-center space-x-2 ${
          active ? "bg-[#444444]" : "bg-[#FF947A]"
        } `}
      >
        <span className="font-productsans text-[12px] text-[#FFFFFF]">
          {selectedOption}
        </span>
        <Dropdown />
      </div>
      <select
        ref={selectRef}
        // value={selectedOption}
        className="opacity-0"
        onChange={(e) => handleChange(e)}
        name={name}
      >
        {/* <option>Select filter</option> */}
        {options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StyledSelect;
