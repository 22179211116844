import ProgressBar from "components/ProgressBar";
import { CommaSeperatedNumber, PlainButton, Table } from "components/general";
import PaginationTab from "components/general/PaginationBar";
import { useAuthContext } from "context";
import { format } from "date-fns";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useGetAllGroups } from "services/api/transactionPaths";
import { GroupData } from "services/api/types";
import { NA } from "utils/constants";
import { handleExportDownload } from "utils/functions";

const Groups = () => {
  const [query, setQuery] = React.useState("");
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = async () => {
    setIsExporting(true);
    await handleExportDownload(`groups/export`, `all-groups`, authToken!);
    setIsExporting(false);
  };

  const { authToken } = useAuthContext();

  const {
    data: allGroups,
    isLoading: isGroupsLoading,
    refetch: refetchGroups,
    isRefetching,
  } = useGetAllGroups({
    queryParams: {
      page: page,
      limit: 20,
      filter: query,
    },
  });

  const columns: TableColumn<GroupData>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      cell: (row, index) => (
        <>
          {allGroups?.meta.currentPage
            ? index + 1 + 20 * (allGroups.meta.currentPage - 1)
            : ""}
        </>
      ),
      width: "70px",
    },
    {
      name: "Group Name",
      width: "275px",
      selector: (row) => row.name,
    },
    {
      name: "Account Number",
      width: "14%",
      selector: (row) =>
        row.groupVirtualAccounts.length > 0
          ? row.groupVirtualAccounts[0].accountNumber
          : NA,
    },
    {
      name: "Current Balance",
      width: "15%",
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A`}
          value={row.walletAmount}
        />
      ),
    },
    {
      name: "Member Count",
      width: "12%",
      cell: (row) => <p className="mx-auto">{row.memberCount}</p>,
    },
    {
      name: "Date Created",
      width: "15%",
      // selector: (row) => format(new Date(row.CreatedAt), "MMMM d, yyyy"),
      // cell: (row) => <>here</>,

      cell: (row) => format(new Date(row.createdAt), "MMMM d, yyyy"),
    },
    // {
    //   name: "Action",
    //   width: "135px",
    //   cell: (row) => (
    //     <button
    //       onClick={() => console.log("messages page")}
    //       className="mx-auto text-0067B4 font-productsans700 text-base"
    //     >
    //       View
    //     </button>
    //   ),
    // },
  ];

  const handleSelectedRowsChange = (x: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => {
    setSelectedRows(x.selectedRows);
  };
  

  //=======making a query search after every 3 seconds provided there's a searched query======//
  const debouncedSearch = React.useRef(
    debounce(() => {
      refetchGroups();
    }, 300)
  ).current;

  useEffect(() => {
    setPage(1);

    if (query) {
      debouncedSearch();
    } else if (query.length === 0) {
      setPage(1);
      refetchGroups();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch, refetchGroups]);
  //==================================================================
  useEffect(() => {
    refetchGroups();
    window.scrollTo(0, 0);
  }, [page, refetchGroups]);

  if (isGroupsLoading) return <ProgressBar />;

  return (
    <div className="">      
      <div className="mt-16">
        <div className="flex items-center justify-between">
          <input
            className="w-1/2 border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
            placeholder="Search keywords, titles or phrases"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="flex gap-3">
            <PlainButton
              title="Export"
              onClick={handleExport}
              isLoading={isExporting}
              disabled={isExporting}
            />
          </div>
        </div>
      </div>
      <div
        className={`mt-6 flex flex-col space-y-9 ${
          isRefetching ? "opacity-30" : ""
        }`}
      >
        <Table
          columns={columns}
          data={allGroups?.content!}
          handleSelectedRowsChange={handleSelectedRowsChange}
        />
      </div>
      <div className="mb-6">
        <PaginationTab
          currentPage={page}
          setCurrentPage={setPage}
          totalPages={allGroups?.meta.totalPages!}
        />
      </div>
    </div>
  );
};

export default Groups;
