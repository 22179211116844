import React, { useState } from "react";
import { GwalaUserActivity } from "interfaces/users";

import userPP from "../../../assets/images/538846-200.png";
import { PlainButton, Table } from "components/general";
import { TableColumn } from "react-data-table-component";
import { SingleUser } from "services/api/types";
import { format } from "date-fns";
import BlockUserModal from "./BlockuserModal";
import ResetPinModal from "./ResetPinModal";
import DetailsCard, { SwitchText } from "./DetailsCard";
// import { format } from "date-fns";

const activity: GwalaUserActivity[] = [
  {
    date: "03/06/2023",
    action: "Account login",
    description: "User logged into their account using email and password",
    ip_address: "192.28.1.0",
    status: "Debit/Wallet",
  },
  {
    date: "03/06/2023",
    action: "Account login",
    description: "User logged into their account using email and password",
    ip_address: "192.28.1.0",
    status: "Debit/Wallet",
  },
];

const UserBio = ({ userDetails }: { userDetails: SingleUser }) => {
  const [blocked, setBlocked] = useState(userDetails.suspended);
  const [blockUser, setBlockUser] = useState(false)
  const [resetPin, setResetPin] = useState(false)
  

  const columns: TableColumn<GwalaUserActivity>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      sortable: false,
      width: "70px",
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Action",
      width: "175px",
      selector: (row) => row.action,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      // cell: (row) => (
      //   <CommaSeperatedNumber
      //     className={`font-productsans text-base text-273B4A ${
      //       row?.type?.includes("Debit") ? "!text-FF0053" : ""
      //     }`}
      //     value={row.amount}
      //   />
      // ),
    },
    {
      name: "I.P Address",
      sortable: true,
      selector: (row) => row.ip_address,
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
    },
  ];
  return (
    <div className="user-bio font-productsans">
      <div className="user-bio-header mb-8 flex items-end justify-between">
        <img
          src={userDetails.profilePicture ?? userPP}
          alt=""
          className="w-[121px] h-[121px] rounded-full"
        />
        <div className="buttons flex space-x-[9px]">
          <button
            type="button"
            // onClick={onClick}
            className="h-[41.44px] px-[17px] border border-D9D9D9 rounded-5 bg-D9D9D9 text-C4C4C4 text-[15px] font-productsans"
          >
            Verify Account
          </button>
          <PlainButton
            title="Reset Password"
            onClick={() => setResetPin(true)}
          />
          <button
            type="button"
            onClick={() => setBlockUser(true)}
            className="h-[41.44px] px-[17px] border border-D9D9D9 rounded-5 bg-FF0053 text-white text-[15px] font-productsans"
          >
            {blocked ? "Unblock Account" : "Block Account"}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-[21px]">
        <DetailsCard title="Full Name" desc={userDetails.fullName} />
        <DetailsCard title="Email Address" desc={userDetails.email} />
        <DetailsCard title="Phone Number" desc={userDetails.phone} />
      </div>
      <div
        className="grid gap-[20px] mt-5"
        style={{ gridTemplateColumns: "0.7fr 1fr 1.3fr 0.8fr 1fr" }}
      >
        <DetailsCard title="Gender" desc="Male" />
        <DetailsCard
          title="Date of Birth"
          desc={format(new Date(userDetails.dob), "dd/MM/yyyy")}
        />
        <DetailsCard title="KYC" desc={userDetails.phone} />
        <DetailsCard
          title="Account Number"
          desc={userDetails.userVirtualAccounts[0].accountNumber}
        />
        <DetailsCard
          title="Account Name"
          desc={userDetails.userVirtualAccounts[0].accountName}
        />
      </div>
      <div className="user-nots mt-7 ">
        <h3 className="text-2xl text-black leading-none">
          User's notification settings
        </h3>
        <div className="grid grid-cols-3 gap-[31px] mt-5">          
          <SwitchText text="Push notification" />
          <SwitchText text="Email notification" />
          <SwitchText text="Auto-update" />
        </div>
      </div>
      <div className="user-activity flex flex-col space-y-6 mt-8">
        <h3 className="text-2xl text-black leading-none">
          User's activity log
        </h3>
        <Table columns={columns} data={activity} />
      </div>
      {blockUser && (
        <BlockUserModal
          closeModal={setBlockUser}
          blocked={blocked}
          setBlocked={setBlocked}
          user={userDetails}
        />
      )}
      {resetPin && (
        <ResetPinModal closeModal={setResetPin} user={userDetails} />
      )}
    </div>
  );
};

export default UserBio;
