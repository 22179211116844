import { PlainButton, Table } from "components/general";
import { Wrapper } from "components/navigation";
import { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import AutomatedMessages from "./automatedMessages";
import { useNavigate } from "react-router-dom";
import { routes } from "utils/constants";
import { newDropDownOptions } from "interfaces/transactions";
import { debounce } from "lodash";
import React from "react";
import StatsCard from "components/general/StatsCard";
import {
  useGetAdminMessages,
  useGetAdminMessagesStats,
} from "services/api/transactionPaths";
import { MessageType } from "services/api/types";
import PaginationTab from "components/general/PaginationBar";
import ProgressBar from "components/ProgressBar";
import moment from "moment";

const messages = [
  {
    date: "03/06/2023",
    title: "Do more with Gwala...",
    delivered: "430/500",
    group: "New users - 7 days",
  },
  {
    date: "09/06/2023",
    title: "New update alert!",
    delivered: "25/25",
  },
  {
    date: "02/06/2023",
    title: "For you and yours",
    delivered: "100/100",
  },
  {
    date: "30/05/2023",
    title: "Happy Easter!!!",
    delivered: "10/25",
    group: "Christians",
  },
  {
    date: "29/05/2023",
    title: "Celebrating Women in our lives",
    delivered: "345/350",
    group: "Female Users",
  },
  {
    date: "27/05/2023",
    title: "New update alert!",
    delivered: "190/190",
  },
];

const Messages = () => {
  const [automatedMessageOpen, setAutomatedMessage] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [query, setQuery] = React.useState("");
  const [messageParams, setMessageParams] = useState({
    messagesSent: "allTime",
  });

  const {
    data: messagesData,
    refetch: refetchMessages,
    isLoading,
  } = useGetAdminMessages({
    queryParams: {
      limit: 20,
      page: page,
      filter: query,
    },
  });
  const {
    data: messagesStats,
    isLoading: isLoadingStats,
    refetch: refetchStats,
  } = useGetAdminMessagesStats({
    queryParams: messageParams,
  });

  const handleParamsChange = (val: string, name: string) => {
    setMessageParams({
      ...messageParams,
      [name]: val,
    });
  };

  const columns: TableColumn<MessageType>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      sortable: false,
      width: "70px",
      cell: (row, index) => (
        <>
          {messagesData?.meta.currentPage
            ? index + 1 + 20 * (messagesData.meta.currentPage - 1)
            : ""}
        </>
      ),
    },
    {
      name: "MESSAGE SENT ON",
      selector: (row) => moment(row.dateSent).format("DD/MM/YY"),
      sortable: true,
      width: "230px",
    },
    {
      name: "MESSAGE TITLE",
      selector: (row) => row.title,
    },
    {
      name: "DELIVERED TO",
      selector: (row) => row.deliveredTo,
      sortable: false,
    },
    {
      name: "Recepients",
      selector: (row) => row.totalRecipients,
      sortable: false,
    },
    {
      name: "GROUP",
      selector: (row) => row.groupName ?? "N/A",
      cell: (row) => (
        <div className="">
          {row.groupName ? (
            <div className="bg-C4C4C4 h-5 flex items-center justify-center px-3 rounded-50">
              <span className="font-productsans text-[12px] text-273B4A">
                {row.groupName}
              </span>
            </div>
          ) : (
            <p className="h-5 flex items-center justify-center px-3 rounded-50">
              N/A
            </p>
          )}
        </div>
      ),
    },
    // {
    //   name: "ACTION",
    //   cell: (row) => (
    //     <button
    //       onClick={() => console.log("messages page")}
    //       className="mx-auto text-0067B4 font-productsans700 text-base"
    //     >
    //       View details
    //     </button>
    //   ),
    // },
  ];

  //=======making a query search after every 3 seconds provided there's a searched query======//
  const debouncedSearch = React.useRef(
    debounce(() => {
      refetchMessages();
    }, 300)
  ).current;

  useEffect(() => {
    setPage(1);

    if (query) {
      debouncedSearch();
    } else if (query.length === 0) {
      setPage(1);
      refetchMessages();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch, refetchMessages]);
  //==================================================================

  useEffect(() => {
    refetchStats();
  }, [messageParams, refetchStats]);

  useEffect(() => {
    refetchMessages();
    window.scrollTo(0, 0);
  }, [page, refetchMessages]);

 

  if (isLoading || isLoadingStats)
    return (
      <Wrapper>
        <ProgressBar />
      </Wrapper>
    );

  return (
    <Wrapper>
      <div className="">
        {automatedMessageOpen ? (
          <AutomatedMessages
            messages={messages}
            closeModal={setAutomatedMessage}
          />
        ) : (
          <>
            <span className="font-productsans text-273B4A text-base">
              <span
                onClick={() => navigate(routes.users)}
                className="cursor-pointer"
              >
                Home{" > "}
              </span>
              <span className="text-BBB5B5">Messages History</span>
            </span>

            <div className="flex gap-4 mt-5">
              <StatsCard
                header="MESSAGES SENT"
                decimal={false}
                dropDownOptions={newDropDownOptions}
                num={messagesStats?.messagesSent!}
                width={312}
                name="messagesSent"
                handleSelect={handleParamsChange}
              />
              <StatsCard
                header="TOTAL MESSAGE GROUPS"
                decimal={false}
                num={messagesStats?.totalMessageGroups!}
                width={312}
                handleSelect={handleParamsChange}
                name="totalMessageGroups"
              />
            </div>
            <div className="mt-8">
              <div className="flex items-center justify-between">
                <input
                  className="w-1/2 border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                  placeholder="Search keywords, titles or phrases"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <div className="flex gap-3">
                  <PlainButton
                    title="Automated messages"
                    onClick={() => setAutomatedMessage(true)}
                  />
                  {/* <PlainButton
                    title="Send message"
                    //   onClick={onSendMessageClick}
                  /> */}                  
                </div>
              </div>
            </div>
            <div className="mt-6 flex flex-col space-y-9">
              <Table columns={columns} data={messagesData?.sanitizedItems!} />
            </div>
            <div className="mb-6">
              <PaginationTab
                currentPage={page}
                setCurrentPage={setPage}
                totalPages={messagesData?.meta.totalPages!}
              />
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default Messages;
