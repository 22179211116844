import { useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { getInputClassNames } from "utils/functions";
import validators from "utils/validators";
import { routes } from "utils/constants";
import { useLoginAdmin } from "services/auth/path";
import { useAuthContext } from "context";
import { toast } from "react-hot-toast";
import { queryClient } from "services/queryClient";
import { Loader2 } from "lucide-react";

interface LoginFormValues {
  email: string;
  password: string;
}

const Login = () => {
  const {
    setLoggedIn,
    loginUser: loginUserContext,
    logoutUser,
    setAuthToken,
  } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    reValidateMode: "onBlur",
  });
  const navigate = useNavigate();
  useEffect(() => {
    logoutUser();
  }, [logoutUser]);

  const { mutate: loginUser, isLoading } = useLoginAdmin({
    onSuccess: (data) => {
      console.log('here', {data})
      // Set data in localStorage
      localStorage.setItem("token", data.token);
      queryClient.setQueryData(["token"], data.token);
      setAuthToken(data.token);
      setLoggedIn(true);
      loginUserContext({
        user: {
          adminId: data.adminId,
          fullName: data.fullName,
          email: data.email,
          role: data.role,
        },
      });
      navigate(routes.dashboard);
      toast.success("Logged in successfully");
    },
  });

  const onSubmit = async (values: LoginFormValues) => {
    const payload = {
      username: values.email,
      password: values.password,
    };

    loginUser(payload);
  };

  return (
    <div className="bg-D9D9D9/[.2] min-h-screen flex items-center justify-center">
      <div className="flex flex-col space-y-5">
        <div className="flex items-center justify-center">
          <Logo />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white rounded p-5 shadow-md flex flex-col space-y-5 min-w-[400px]"
        >
          <div className="form-control w-full flex flex-col space-y-[5px]">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="email"
              placeholder="Type in your email"
              className={getInputClassNames(!!errors.email)}
              {...register("email", {
                validate: (v) => validators.isValidEmail(v),
              })}
            />
          </div>
          <div className="form-control w-full flex flex-col space-y-[5px]">
            <label className="label">
              <span className="label-text">Password</span>
            </label>
            <input
              type="password"
              placeholder="Type in your password"
              className={getInputClassNames(!!errors.password)}
              {...register("password", {
                validate: (v) => validators.isRequiredString(v),
              })}
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="font-manrope700 bg-273B4A rounded-lg py-3 px-2 text-white flex gap-2 items-center justify-center disabled:opacity-60"
          >
            Sign in
            {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
// <Loader2 className="mr-2 h-4 w-4 animate-spin" />
