import { CommaSeperatedNumber, PlainButton, Table } from "components/general";
import PaginationTab from "components/general/PaginationBar";
import React, { useEffect } from "react";
import { TableColumn } from "react-data-table-component";
import { CommissionData, CommissionsResponse } from "services/api/types";
import { NA } from "utils/constants";

type Props = {
  commissions: CommissionsResponse;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isRefetching: boolean;
};
export const CommissionsTable = ({ commissions, page, setPage, isRefetching }: Props) => {
  const [query, setQuery] = React.useState("");

  const columns: TableColumn<CommissionData>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      sortable: false,
      width: "70px",
      cell: (row, index) => (
        <>
          {commissions?.meta.currentPage
            ? index + 1 + 20 * (commissions.meta.currentPage - 1)
            : ""}
        </>
      ),
    },
    {
      name: "Transaction ID",
      cell: (row) => <>{row.transactionId ?? NA}</>,
      sortable: true,
      width: "230px",
    },
    {
      name: "Utility/ Product",
      selector: (row) => row.product,
    },
    {
      name: "Amount",
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A`}
          value={row.commission}
        />
      ),
      sortable: false,
    },
    {
      name: "Cashback",
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A`}
          value={row.cashback}
        />
      ),
      sortable: false,
    },
    {
      name: "Source",
      selector: (row) => row.source ?? "N/A",
      sortable: false,
    },
    // {
    //   name: "ACTION",
    //   cell: (row) => (
    //     <button
    //       onClick={() => console.log("messages page")}
    //       className="mx-auto text-0067B4 font-productsans700 text-base"
    //     >
    //       View details
    //     </button>
    //   ),
    // },
  ];
  useEffect(() => {
    setPage(1);
  }, []);

  return (
    <div className={`${isRefetching ? "opacity-30" : ""}`}>
      <div className="mt-8">
        <div className="flex items-center justify-between">
          <input
            className="w-1/2 border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
            placeholder="Search keywords, titles or phrases"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="flex gap-3">
            <PlainButton title="Filter records" />
            <PlainButton title="Export" />
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col space-y-9">
        <Table columns={columns} data={commissions?.content!} />
      </div>
      <div className="mb-6">
        <PaginationTab
          currentPage={page}
          setCurrentPage={setPage}
          totalPages={commissions?.meta.totalPages!}
        />
      </div>
    </div>
  );
};
