import { CommaSeperatedNumber, Table } from "components/general";
import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { debounce } from "lodash";
import { PayoutToAgents } from "services/api/types";
import {
  useGetPayoutsToAgents,
} from "services/api/path";
import ProgressBar from "components/ProgressBar";
import PaginationTab from "components/general/PaginationBar";
import moment from "moment";


type TransProps = {
  email: string;
};

const PayoutsToAgents = ({ email }: TransProps) => {
  const [query, setQuery] = React.useState("");
  const [page, setPage] = useState(1);

  const {
    data: payoutToAgents,
    isLoading: isPayoutsLoading,
    refetch: refetchTrans,
    isRefetching,
  } = useGetPayoutsToAgents({
    path: email as string,
    queryParams: {
      page: page,
      limit: 20,
      filter: query ?? undefined,
    },
  }); 

  //=======making a query search after every 3 seconds provided there's a searched query======//
  const debouncedSearch = React.useRef(
    debounce(() => {
      refetchTrans();
    }, 300)
  ).current;
  useEffect(() => {
    setPage(1);

    if (query) {
      debouncedSearch();
    } else if (query.length === 0) {
      setPage(1);
      refetchTrans();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch, refetchTrans]);
  //==================================================================

  useEffect(() => {
    window.scrollTo(0, 0);
    refetchTrans();
  }, [page, refetchTrans]);

  const columns: TableColumn<PayoutToAgents>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      cell: (row, index) => (
        <>
          {payoutToAgents?.meta.currentPage
            ? index + 1 + 20 * (payoutToAgents.meta.currentPage - 1)
            : ""}
        </>
      ),
      width: "70px",
    },
    {
      name: "Transaction ID",
      //   selector: (row) => row.transactionId,
      cell: (row) => <div className="text-black">{row.transactionId}</div>,
    },
    {
      name: "Payout Date",
      selector: (row) => moment(row.payoutDate).format("DD/MM/YY"),
      sortable: true,
      width: "230px",
    },
    {
      name: "Payout Amount",
      selector: (row) => row.payoutAmount,
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A `}
          value={row.payoutAmount}
        />
      ),
    },
    {
      name: "Agent Code",
      selector: (row) => row.agentCode,
      cell: (row) => {
        return <>{row.agentCode}</>;
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          //   onClick={() => navigate(routes.getUserDetailRoute("1"))}
          className="mx-auto text-273B4A font-productsans700 text-base"
        >
          Download Receipt
        </button>
      ),
    },
  ];

  if (isPayoutsLoading) return <ProgressBar />;
  return (
    <div className="flex flex-col space-y-6">      
      <div className="flex items-center justify-between">
        <input
          className="w-1/2 border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
          placeholder="Search with transaction number, amount or date"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
       
      </div>
      <div className={`${isRefetching ? "opacity-30" : ""} space-y-7`}>
        <Table
          columns={columns}
          data={payoutToAgents == null ? [] : payoutToAgents?.content!}
        />
      </div>
      <div className="mb-6">
        <PaginationTab
          currentPage={page}
          setCurrentPage={setPage}
          totalPages={payoutToAgents?.meta.totalPages!}
        />
      </div>
    </div>
  );
};

export default PayoutsToAgents;
