import ModalWrapper from "components/utils/ModalWrapper";
import toast from "react-hot-toast";
import { useResetPin } from "services/api/mutation-paths";
import { SingleUser } from "services/api/types";
import { Controller, useForm } from "react-hook-form";

type ResetProps = {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  user: SingleUser;
};
const ResetPinModal = ({
  closeModal,
  user,
}: ResetProps) => {
    const { getValues, control, watch } = useForm();
    const watchOtp = watch("otp")?.length > 5 && watch("otp")?.length < 7 && !getValues('otp')?.includes('.') ;

  const { mutate: ResetPin, isLoading: isReseting } = useResetPin({
    onSuccess: () => {
      toast.success("Password reset successfull");
      closeModal(false)
    },
  });
  const handleReset = () => {
    const newPin = getValues('otp')
    ResetPin({
      newPin: newPin,
      userEmail: user.email,
    });
  };

  return (
    <ModalWrapper>
      <div className="p-4 w-[33rem]">
        <div className="flex justify-end">
          <span
            className="block h-10 w-10 bg-D9D9D9 rounded-full cursor-pointer"
            onClick={() => closeModal(false)}
          ></span>
        </div>
        <div className="px-12">
          <div className="my-5">
            <h1 className="mb-2 text-xl">Enter new pin to reset user pin</h1>
            <p>Name : {user.fullName}</p>
            <p>Email : {user.email}</p>
          </div>
          <Controller
            name="otp"
            rules={{
              required: true,
              minLength: 6,
              maxLength: 6,
              pattern: /^[0-9]+$/,
            }}
            control={control}
            render={({ field }) => (
              <input
                className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                placeholder="Enter new pin"
                {...field}
                // value={newPin}
                type="number"
                step="1"
                // onChange={(e) => setNewPin(e.target.value)}
              />
            )}
          />
          <div className="ml-auto mb-5 mt-8">
            <button
              type="button"
              //   onClick={()=>console.log(getValues('otp'))}
              onClick={handleReset}
              disabled={isReseting || !watchOtp}
              className="h-[41.44px] px-[17px] border border-D9D9D9 rounded-5 bg-FF0053 text-white text-[15px] font-productsans disabled:opacity-30"
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ResetPinModal;
