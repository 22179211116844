import { Wrapper } from "components/navigation";
import clsx from "classnames";
import React, { useState, useMemo, useEffect } from "react";
import {
  UserBio,
  UserPendingTransactions,
  UserPurchases,
  UserWalletTransactions,
} from "components/users";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "utils/constants";
import UserGroups from "components/users/UserGroups";
import {
  useGetSingleUserDetails,
  useGetSingleUserStats,
} from "services/api/path";
import ProgressBar from "components/ProgressBar";
import StatsCard from "components/general/StatsCard";
import { UserReferees } from "components/users/UserReferees";

const TABS = [
  { name: "WALLET TRANSACTIONS", id: "wallet_transactions" },
  { name: "PENDING TRANSACTIONS", id: "pending_transactions" },
  // { name: "PURCHASES", id: "purchases" },
  { name: "MY REFEREES", id: "my_referees" },
  { name: "USER BIO", id: "user_bio" },
  { name: "GROUPS", id: "groups" },
];

const UserDetail = () => {
  const [activeTab, setActiveTab] = useState(TABS[0].id);
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();
  const { email } = useParams();

  const { data: singleUserStats, isLoading } = useGetSingleUserStats({
    path: email as string,
  });

  const { data: singleUser, isLoading: isUserLoading } =
    useGetSingleUserDetails({
      path: email as string,
    });

  const singleUserStatsData = useMemo(
    () =>
      singleUserStats ?? {
        walletBalance: 0,
        giftWalletBalance: 0,
        totalGroup: 0,
        totalBucket: 0,
      },
    [singleUserStats]
  );
  useEffect(() => {
    // Use setTimeout to hide the loader after 0.5 seconds
    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    // Clear the timeout if the component unmounts
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  if (isLoading || isUserLoading || showLoader)
    return (
      <Wrapper>
        <ProgressBar />
      </Wrapper>
    );

  return (
    <Wrapper>
      <div className="mt-[18px]">
        <span className="font-productsans text-273B4A text-base">
          <span
            onClick={() => navigate(routes.users)}
            className="cursor-pointer"
          >
            All users
          </span>{" "}
          {">"} <span className="text-BBB5B5">{singleUser?.fullName}</span>
        </span>
        <div className="mt-[13px] flex gap-3">
          <StatsCard
            header="USER WALLET BALANCE"
            num={singleUserStatsData.walletBalance!}
            decimal={true}
            name="walletBalance"
            handleSelect={() => console.log("here")}
          />
          {/* <StatsCard
            header="USER GIFT WALLET"
            num={singleUserStatsData.giftWalletBalance!}
            decimal={true}
            name="totalRegisteredUsers"
            width={225}
            handleSelect={() => console.log("here")}
          /> */}
          <StatsCard
            header="TOTAL GROUPS"
            num={singleUserStatsData.totalGroup!}
            decimal={false}
            name="totalGroups"
            width={225}
            handleSelect={() => console.log("here")}
          />
          {/* <StatsCard
            header="TOTAL BUCKETS"
            num={singleUserStatsData.totalBucket!}
            decimal={false}
            name="totalRegisteredUsers"
            handleSelect={() => console.log("here")}
          /> */}
        </div>
        <div className="mt-[35px] flex space-x-10 items-center">
          <div className="flex space-x-7 items-center">
            {TABS.map((tab) => (
              <button
                onClick={() => setActiveTab(tab.id)}
                className={clsx(
                  "text-BBB5B5 transition-all font-productsans text-base border-b-[4px] border-b-transparent",
                  {
                    "!border-b-273B4A font-productsans700 !text-273B4A":
                      activeTab === tab.id,
                  }
                )}
              >
                {tab.name}
              </button>
            ))}
          </div>
          {singleUser?.isVerified ? (
            <div className="bg-E5FDD1 h-5 flex items-center justify-center px-3 rounded-50">
              <span className="font-productsans text-[12px] text-273B4A">
                Account Verified
              </span>
            </div>
          ) : (
            <div className="bg-FFACC7 h-5 flex items-center justify-center px-3 rounded-50">
              <span className="font-productsans text-[12px] text-273B4A">
                Pending Verification
              </span>
            </div>
          )}
        </div>
        <div className="mt-[45px]">
          {/* content */}
          {activeTab === "wallet_transactions" ? (
            <UserWalletTransactions email={email!} />
          ) : activeTab === "pending_transactions" ? (
            <UserPendingTransactions email={email!} />
          ) : activeTab === "purchases" ? (
            <UserPurchases />
          ) : activeTab === "user_bio" ? (
            <UserBio userDetails={singleUser!} />
          ) : activeTab === "my_referees" ? (
            <UserReferees email={email!} />
          ) : (
            <UserGroups email={email!} />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default UserDetail;
