import React, { useState } from "react";
import clsx from "classnames";
import { useSendMessage, useSendMessageAutomated, useSendMessageGroup } from "services/api/mutation-paths";
import { Controller, useForm } from "react-hook-form";
import { capitalizeText } from "components/utils/string";
import toast from "react-hot-toast";

interface SendMessageModalProps {
  visible: boolean;
  onClose: () => void;
  recipients: any[];
  category: string;
}

const SendMessageModal = ({
  visible,
  onClose,
  recipients,
  category,
}: SendMessageModalProps) => {
  const { getValues, control, formState : {isValid} , watch} = useForm();
  const [groupSave, setGroupSave] = useState(false)

  const { mutate: sendMessage, isLoading: isSending } = useSendMessage({
    onSuccess: () => {
      onClose();
      toast.success("Message sent successfully");

    },
  });
  const { mutate: sendMessageGroup, isLoading: isSendingToGroup } = useSendMessageGroup({
    onSuccess: () => {
      onClose();
      toast.success("Message sent successfully");

    },
  });
  const { mutate: sendMessageAuto, isLoading: isSendingAuto } =
    useSendMessageAutomated({
      onSuccess: () => {
        onClose();
      toast.success("Message sent successfully");

      },
    });

  const handleSendMessage = () => {
    const title = getValues("title");
    const message = getValues("message");
    const groupTitle = groupSave ? getValues("groupName") : ''

    if (category === "users") {
      sendMessage({
        subject: title,
        message: message,
        users: recipients.map((item) => item.email),
        saveAsGroup : groupSave,
        groupTitle: groupTitle
      });
      return
    }

    if (category === "groups") {
      sendMessageGroup({
        subject: title,
        message: message,
        adminMessageGroupId: recipients[0].groupId,
      });
      return;
    }

    if (category === "automated") {
      sendMessageAuto({
        subject: title,
        message: message,
        // users: recipients.map((item) => item.email),
        users: ["anyaelennamdi@gmail.com", "segunzacheusi@gmail.com"],
        frequency: 1,
      });
      return;
    }
  };
  return (
    <div
      role="presentation"
      onClick={onClose}
      className={clsx(
        "fixed top-0 left-0 z-[999] w-full h-screen opacity-0 pointer-events-none overflow-y-auto bg-black/[.6] flex items-center justify-center",
        {
          "!opacity-100 !pointer-events-auto": visible,
        }
      )}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white rounded-[10px] py-[70px] px-[45px] pb-[90px] xl:w-[1155px] flex flex-col"
      >
        <h3 className="ml-[22px] text-273B4A text-[33px] font-productsans">
          Write a message
        </h3>
        <div className="mt-[34px] flex items-center justify-between px-[22px] bg-F5F5F5 border border-D9D9D9 h-[47px] rounded-[10px]">
          <p className="font-productsans text-[18px] text-273B4A">
            Message will be sent to {recipients.length} recipient based on your
            selection
          </p>
          {category !== "groups" && (
            <label
              className="font-productsans text-[18px] text-0067B4 flex gap-2 items-center cursor-pointer"
              htmlFor="groupSave"
            >
              <input
                type="checkbox"
                name="groupSave"
                id="groupSave"
                onChange={(e) => setGroupSave(e.target.checked)}
              />
              save as group
            </label>
          )}
        </div>
        <div className="flex items-center mt-3 flex-wrap gap-y-2">
          Recipient{recipients.length > 1 ? "s" : ""} :{" "}
          {recipients
            .filter((user, i) => i < 10)
            .map((user) => (
              <p className="bg-F5F5F5 py-1 rounded-full p-2 ml-2">
                {capitalizeText(user.fullName ?? user.name)}
              </p>
            ))}
          <p className="ml-3">
            {recipients.length > 10 && `+${recipients.length - 10} others`}
          </p>
        </div>
        <form className="mt-[15px] flex flex-col space-y-[33px]">
          <div className="flex gap-5">
            {groupSave && (
              <Controller
                name="groupName"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    className="text-273B4A border outline-none border-D9D9D9 rounded-[10px] px-[22px] py-3 font-productsans text-[22px] w-1/2"
                    placeholder="Add group name"
                    {...field}
                  />
                )}
              />
            )}
            <Controller
              name="title"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <input
                  type="text"
                  className={`text-273B4A border outline-none border-D9D9D9 rounded-[10px] px-[22px] py-3 font-productsans text-[22px] ${
                    groupSave ? "w-1/2" : "w-full"
                  }`}
                  placeholder="Add message title"
                  {...field}
                />
              )}
            />
          </div>
          <div className="relative">
            <p className="absolute right-0 -top-6">{watch("message")?.length ?? 0}/200</p>
            <Controller
              name="message"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <textarea
                  className="h-[122px] text-273B4A border outline-none border-D9D9D9 rounded-[10px] px-[22px] py-4 w-full font-productsans text-[22px]"
                  placeholder="Write a message (200 characters)"
                  maxLength={200}
                  {...field}
                />
              )}
            />
          </div>
        </form>
        <button
          className="bg-[#273B4A] text-white rounded-3xl w-[23rem] py-3 mx-auto mt-16 disabled:opacity-30"
          onClick={handleSendMessage}
          disabled={!isValid || isSending || isSendingAuto || isSendingToGroup}
        >
          Send Message
        </button>
      </div>
    </div>
  );
};

export default SendMessageModal;
