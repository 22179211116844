
import { SingleAggDetails } from "services/api/types";
import { format } from "date-fns";
import DetailsCard from "components/users/UserBio/DetailsCard";
import { NA } from "utils/constants";
import { useActivateAgg, useDeactivateAgg } from "services/api/mutation-paths";
import { Loader2 } from "lucide-react";


const AggregatorBio = ({ userDetails }: { userDetails: SingleAggDetails }) => {
  const { mutate: activateAggFn, isLoading: isActivating } = useActivateAgg({});
  const { mutate: deactivateAggFn, isLoading: isDeactivating } =
    useDeactivateAgg({});

  return (
    <div className="user-bio font-productsans">
      <div className="user-bio-header mb-8 flex items-end justify-between">
        <div className="">&nbsp;</div>
        <div className="buttons flex space-x-[9px]">
          <button
            type="button"
            className="h-[41.44px] px-[17px] border border-D9D9D9 rounded-5 bg-D9D9D9 text-black text-[15px] font-productsans flex gap-3 items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => {
              activateAggFn({
                email: userDetails.email,
              });
            }}
            disabled={isActivating || isDeactivating || userDetails.isActive}
          >
            Activate
            {isActivating && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          </button>
          <button
            type="button"
            className="h-[41.44px] px-[17px] border border-D9D9D9 rounded-5 bg-FF0053 text-white text-[15px] font-productsans flex gap-3 items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => {
              deactivateAggFn({
                email: userDetails.email,
              });
            }}
            disabled={isActivating || isDeactivating|| !userDetails.isActive}
          >
            Deactivate
            {isDeactivating && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-[21px]">
        <DetailsCard title="Full Name" desc={userDetails.fullname} />
        <DetailsCard title="Email Address" desc={userDetails.email} />
        <DetailsCard title="Phone Number" desc={userDetails.phone} />
      </div>
      <div
        className="grid gap-[20px] mt-5"
        style={{ gridTemplateColumns: "0.7fr 1fr 1.3fr 0.8fr 1fr" }}
      >
        <DetailsCard title="Gender" desc="Male" />
        <DetailsCard
          title="Last Login"
          desc={format(new Date(userDetails.dateCreated), "dd/MM/yyyy")}
        />
        <DetailsCard title="KYC" desc={userDetails.phone} />
        <DetailsCard
          title="Account Number"
          desc={userDetails.accountNumber! ?? NA}
        />
        <DetailsCard title="Account Name" desc={userDetails.bankName! ?? NA} />
      </div>
    </div>
  );
};

export default AggregatorBio;
