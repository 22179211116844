import { useEffect, useMemo } from "react";
import { useGetPersonalWalletTransactionsStats } from "services/api/transactionPaths";
import { useDashboardContext } from "context";
import DashboardCard from "../cards/dashboardCardPersonal";
import { DashboardPersonalWallet } from "services/api/types";
import ProgressBar from "components/ProgressBar";

const PersonalWalletTab = () => {
  const { personalParams } = useDashboardContext();

  const {
    data: personalWalletStats,
    isLoading,
    refetch: refetchStats,
    isRefetching,
  } = useGetPersonalWalletTransactionsStats({
    queryParams: personalParams,
  });

  const personalWalletStatsData = useMemo(
    () => personalWalletStats ?? {},
    [personalWalletStats]
  );
  useEffect(() => {
    refetchStats();
  }, [personalParams, refetchStats]);

  if (isLoading) return <ProgressBar />;

  return (
    <div className="grid grid-cols-4 col-span-4 justify-between gap-6 mb-8">
      {Object.keys(personalWalletStatsData as DashboardPersonalWallet).map(
        (key, i) => (
          <DashboardCard
            num={i}
            name={`${key}`}
            deets={personalWalletStats!}
            refetching={isRefetching}
            key={i}
          />
        )
      )}
    </div>
  );
};

export default PersonalWalletTab;
