import React from 'react'
import { capitalizeText } from './string'

interface StatusColorProps {
    status: string
    disable?: boolean
}

const StatusColor: React.FC<StatusColorProps> = ({ status, disable = false }) => {
    let badgeColor: string
    let statuss = status.toLowerCase()

    switch (statuss) {
        case 'success':
            badgeColor = "text-273B4A bg-[#E5FDD1]";
            break
        case 'pending':
            badgeColor = "text-273B4A bg-[#fff247]";
            break
        case 'failed':
            badgeColor = 'bg-FFACC7 text-273B4A'
            break
        case 'partialApproval':
            badgeColor = 'text-[#FF9152]'
            break
        case 'reimbursed':
            badgeColor = 'text-[#5C9BFF]'
            break
        case 'disabled':
            badgeColor = 'bg-[#F9F9F9] text-[#DADCE0]'
            break
        case 'closed':
        default:
            badgeColor = 'text-[purple]'
            break
    }

    return (
      <div
        className={` h-6 flex items-center justify-center px-3 rounded-50 ${
          disable ? "bg-[#f9f9f9] text-[#DADCE0]" : badgeColor
        }`}
      >
        <span className="font-productsans text-[14px] text-273B4A">
          {capitalizeText(status === "denied" ? "Declined" : status)}
        </span>
      </div>
    );
}

export default StatusColor
