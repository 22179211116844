import React, { useEffect } from "react";
import { CommaSeperatedNumber, PlainButton, Table } from "components/general";
import PaginationTab from "components/general/PaginationBar";
import { TableColumn } from "react-data-table-component";
import { CashbackData, CashbackResponse } from "services/api/types";
import { NA } from "utils/constants";

type Props = {
    billsCommision: CashbackResponse;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    isRefetchingCashback: boolean;
};

export const BillsComissionTable = ({ billsCommision, page, setPage, isRefetchingCashback }: Props) => {
    const [query, setQuery] = React.useState("");

    const columns: TableColumn<CashbackData>[] = [
        {
            name: "S/N",
            selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
            sortable: false,
            width: "70px",
            cell: (row, index) => (
                <>
                    {billsCommision?.meta.currentPage
                        ? index + 1 + 20 * (billsCommision.meta.currentPage - 1)
                        : ""}
                </>
            ),
        },
        {
            name: "Transaction ID",
            cell: (row) => <>{row.transactionId ?? NA}</>,
            sortable: true,
            width: "230px",
        },
        {
            name: "Utility/ Product",
            selector: (row) => row.product,
        },
        // {
        //   name: "Amount",
        //   cell: (row) => (
        //     <CommaSeperatedNumber
        //       className={`font-productsans text-base text-273B4A`}
        //       value={row.cashback}
        //     />
        //   ),
        //   sortable: false,
        // },
        {
            name: "Cashback",
            cell: (row) => (
                <CommaSeperatedNumber
                    className={`font-productsans text-base text-273B4A`}
                    value={row.cashback}
                />
            ),
            sortable: false,
        },
        {
            name: "Source",
            selector: (row) => row.source ?? "N/A",
            sortable: false,
        },
        // {
        //   name: "ACTION",
        //   cell: (row) => (
        //     <button
        //       onClick={() => console.log("messages page")}
        //       className="mx-auto text-0067B4 font-productsans700 text-base"
        //     >
        //       View details
        //     </button>
        //   ),
        // },
    ];

    useEffect(() => {
        setPage(1);
    }, []);

    return (
        <div className={`${isRefetchingCashback ? 'opacity-30' : ''}`}>
            <div className="mt-8">
                <div className="flex items-center justify-between">
                    <input
                        className="w-1/2 border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                        placeholder="Search keywords, titles or phrases"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                    <div className="flex gap-3">
                        <PlainButton title="Filter records" />
                        <PlainButton title="Export" />
                    </div>
                </div>
            </div>
            <div className="mt-6 flex flex-col space-y-9">
                <Table columns={columns} data={billsCommision?.content!} />
            </div>
            <div className="mb-6">
                <PaginationTab
                    currentPage={page}
                    setCurrentPage={setPage}
                    totalPages={billsCommision?.meta.totalPages!}
                />
            </div>
        </div>
    );
};
