import { Wrapper } from "components/navigation";
import TransactionCards from "./transactionCards";
import { useGetEarningsByTrans, useGetEarningsByUser, useGetGwalaEarningsByFees, useGetTransactionsStats } from "services/api/transactionPaths";
import ProgressBar from "components/ProgressBar";
import TransactionTable from "./transcationTable";


const TransactionPage = () => {
    const { data: genTransStats, isLoading: isLoadingTransStats } =
      useGetTransactionsStats({
        queryParams: {
          totalWalletBalance: "allTime",
          totalActiveWallet: "allTime",
          totalGroupBalance: "allTime",
          totalInflow: "allTime",
        },
      });

      const { data : earningsByUser, isLoading : isLoadingEBU} = useGetEarningsByUser({})
      const { data: gwalaEarningsByFees, isLoading: isLoadingGEF } =
        useGetGwalaEarningsByFees({});
      const { data: earningsByTrans, isLoading: isLoadingEBT } =
        useGetEarningsByTrans({});

    return (
      <Wrapper>
        {isLoadingTransStats || isLoadingEBT || isLoadingEBU || isLoadingGEF ? (
          <ProgressBar />
        ) : (
          <div className="">
            <TransactionCards transStats={genTransStats!} earningsByUser={earningsByUser!} earningsByTrans={earningsByTrans!} gwalaEarningsByFees={gwalaEarningsByFees!} />
            <TransactionTable />
          </div>
        )}
      </Wrapper>
    );
}
 
export default TransactionPage;