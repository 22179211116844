import React from "react";
import clsx from "classnames";
import { Controller, useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { useRegisterAgg } from "services/api/mutation-paths";
import toast from "react-hot-toast";
import close from "../../assets/icons/general/close.svg";


interface RegisterAggProps {
  visible: boolean;
  onClose: () => void;
}

export default function RegisterAggregator({
  visible,
  onClose,
}: RegisterAggProps) {
  const {
    getValues,
    control,
    formState: { isValid },
  } = useForm();

  const { mutate: registerAggFn, isLoading } = useRegisterAgg({
    onSuccess: () => {
      toast.success("Registration was successful");
      onClose();
    },
  });

  const handleRegister = () => {
    const name = getValues("fullName");
    const email = getValues("email");
    const state = getValues("state");
    const city = getValues("city");
    const phone = getValues("phone");
    const password = getValues("password");
    registerAggFn({ fullName: name, email, state, city, phone, password });
  };

  return (
    <div
      role="presentation"
      onClick={onClose}
      className={clsx(
        "fixed top-0 left-0 z-[999] w-full h-screen opacity-0 pointer-events-none overflow-y-auto bg-black/[.6] flex items-center justify-center",
        {
          "!opacity-100 !pointer-events-auto": visible,
        }
      )}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white rounded-[10px] py-[30px] px-[45px] pb-[50px] xl:w-[655px] flex flex-col"
      >
        <div className="flex justify-between items-center mb-[32px] animate-slideleft">
          <h3 className=" text-273B4A text-[28px] font-productsans">
            Register Aggregator
          </h3>
          <span
            className="p-3 bg-D9D9D9 rounded-full cursor-pointer"
            onClick={onClose}
          >
            <img src={close} alt="" />
          </span>
        </div>

        <form className="grid grid-cols-2 gap-[20px]">
          <Controller
            name="fullName"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className="text-273B4A border outline-none border-D9D9D9 rounded-[10px] px-[12px] py-3 font-productsans text-[20px]"
                placeholder="Full name"
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <input
                type="email"
                className="text-273B4A border outline-none border-D9D9D9 rounded-[10px] px-[12px] py-3 font-productsans text-[20px]"
                placeholder="Email address"
                {...field}
              />
            )}
          />

          <Controller
            name="state"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className="text-273B4A border outline-none border-D9D9D9 rounded-[10px] px-[12px] py-3 font-productsans text-[20px]"
                placeholder="State"
                {...field}
              />
            )}
          />
          <Controller
            name="city"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className="text-273B4A border outline-none border-D9D9D9 rounded-[10px] px-[12px] py-3 font-productsans text-[20px]"
                placeholder="City"
                {...field}
              />
            )}
          />
          <Controller
            name="phone"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <input
                type="tel"
                className="text-273B4A border outline-none border-D9D9D9 rounded-[10px] px-[12px] py-3 font-productsans text-[20px]"
                placeholder="Phone number"
                {...field}
              />
            )}
          />
          <Controller
            name="password"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <input
                type="password"
                className="text-273B4A border outline-none border-D9D9D9 rounded-[10px] px-[12px] py-3 font-productsans text-[20px]"
                placeholder="Password"
                {...field}
              />
            )}
          />
        </form>
        <button
          className="bg-[#273B4A] text-white rounded-3xl w-[23rem] py-3 mx-auto mt-16 disabled:opacity-60 flex gap-2 items-center justify-center"
          onClick={handleRegister}
          disabled={!isValid || isLoading}
        >
          Register
          {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
        </button>
      </div>
    </div>
  );
}
