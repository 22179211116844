import {
  trans_source,
  trans_status,
} from "components/general/FilterModals/transactionFilter";
import ModalWrapper from "components/utils/ModalWrapper";
import { useAuthContext } from "context";
import { Loader2 } from "lucide-react";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { handleExportDownload } from "utils/functions";

type ExportProps = {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function ExportTransModalTable({ closeModal }: ExportProps) {
  const {
    getValues,
    control,
    watch,    
  } = useForm();
  const [isExporting, setIsExporting] = useState(false);
  const { authToken } = useAuthContext();
  const typeRef = useRef<HTMLSelectElement>(null);

  const handleExport = async () => {
    const startDate = getValues("startDate");
    const endDate = getValues("endDate");
    const type = getValues("transactionType");
    const status = getValues("transactionStatus");
    const source = getValues("transactionSource");

    const queryString = [
      type && `type=${type}`,
      status && `status=${status}`,
      source && `source=${source}`,
    ]
      .filter(Boolean)
      .join("&");
    setIsExporting(true);
    await handleExportDownload(
      `transactions/export-table?startDate=${startDate}&endDate=${endDate}${
        queryString.length > 0 ? `&${queryString}` : ""
      }`,
      "all-transactions-table",
      authToken!
    );
    setIsExporting(false);
    closeModal(false);
  };
  return (
    <ModalWrapper>
      <div className="p-4 w-[33rem]">
        <div className="flex justify-end">
          <span
            className="block h-10 w-10 bg-D9D9D9 rounded-full cursor-pointer"
            onClick={() => closeModal(false)}
          ></span>
        </div>
        <div className="px-12">
          <div className="mb-4">
            <h1 className="mb-2 text-xl">Select filters for export</h1>
          </div>
          <div className="grid grid-cols-2 items-center w-[330px]">
            <p>
              Start date : <span className="text-[red]">*</span>
            </p>

            <Controller
              name="startDate"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <input
                  className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                  placeholder="Select start date"
                  {...field}
                  type="date"
                  step="1"
                  max={new Date().toISOString().split("T")[0]}
                  //   onChange={(e) => setNewPin(e.target.value)}
                />
              )}
            />
          </div>
          <div className="grid grid-cols-2 items-center w-[330px] mt-3">
            <p>
              End date : <span className="text-[red]">*</span>
            </p>
            <Controller
              name="endDate"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <input
                  className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                  placeholder="Select end date"
                  {...field}
                  type="date"
                  step="1"
                  min={watch("startDate")}
                  disabled={watch("startDate")?.length < 1}
                  max={new Date().toISOString().split("T")[0]}
                  // onChange={(e) => setNewPin(e.target.value)}
                />
              )}
            />
          </div>
          <div className="flex items-center gap-8 mt-3">
            <p>Transaction type : </p>

            <Controller
              name="transactionType"
              rules={
                {
                  // required: true,
                }
              }
              control={control}
              render={({ field }) => (
                <select
                  {...field}
                  ref={typeRef}
                  // value={selectedOption}
                  className="bg-[#F5F5F5] p-2 rounded-md h-10"
                >
                  <option>Select duration</option>
                  {trans_type.map((option, index) => (
                    <option value={option.tag} key={index}>
                      {option.name}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
          <div className="flex items-center gap-4 mt-3">
            <p>Transaction status : </p>

            <Controller
              name="transactionStatus"
              rules={
                {
                  // required: true,
                }
              }
              control={control}
              render={({ field }) => (
                <select
                  {...field}
                  ref={typeRef}
                  // value={selectedOption}
                  className="bg-[#F5F5F5] p-2 rounded-md h-10"
                >
                  <option>Select status</option>
                  {trans_status.map((option, index) => (
                    <option value={option} key={index}>
                      {option}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
          <div className="flex items-center gap-4 mt-3">
            <p>Transaction source : </p>

            <Controller
              name="transactionSource"
              rules={
                {
                  // required: true,
                }
              }
              control={control}
              render={({ field }) => (
                <select
                  {...field}
                  ref={typeRef}
                  // value={selectedOption}
                  className="bg-[#F5F5F5] p-2 rounded-md h-10"
                >
                  <option>Select duration</option>
                  {trans_source.map((option, index) => (
                    <option value={option} key={index}>
                      {option}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>

          <div className="ml-auto mb-5 mt-8">
            <button
              type="button"
              //   onClick={()=>console.log(getValues('otp'))}
              onClick={handleExport}
              disabled={
                isExporting || !(watch("startDate") && watch("endDate"))
              }
              className="h-[41.44px] px-[17px] flex gap-3 items-center justify-center border border-D9D9D9 rounded-5 bg-273B4A text-white text-[15px] font-productsans disabled:opacity-60"
            >
              Export transactions
              {isExporting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

const trans_type = [
  {
    name: "Inflow to wallet",
    tag: "walletInflow",
  },
  {
    name: "Inflow to Group",
    tag: "groupInflow",
  },
  {
    name: "Outflow to Wallet",
    tag: "walletOutflow",
  },
  {
    name: "Outflow to Group",
    tag: "groupOutflow",
  },
  {
    name: "Wallet to Group",
    tag: "walletToGroup",
  },
  {
    name: "Group to Wallet",
    tag: "groupToWallet",
  },
  {
    name: "Wallet to Wallet",
    tag: "walletToWallet",
  },
  {
    name: "Group to Group",
    tag: "groupToGroup",
  },
  {
    name: "Bill Payment",
    tag: "billPayment",
  },
];