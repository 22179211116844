import { CommaSeperatedNumber, PlainButton, Table } from "components/general";
import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { debounce } from "lodash";
import { SubAgentLog } from "services/api/types";
import { useGetSingleAggLog } from "services/api/path";
import ProgressBar from "components/ProgressBar";
import PaginationTab from "components/general/PaginationBar";
import { handleExportDownload } from "utils/functions";
import { useAuthContext } from "context";
import { useActivateAgg, useDeactivateAgg } from "services/api/mutation-paths";

type Props = {
  email: string;
};

const AggregatorLog = ({ email }: Props) => {
  const [query, setQuery] = React.useState("");
  const [page, setPage] = useState(1);
  const [isExporting, setIsExporting] = useState(false);
  const { authToken } = useAuthContext();

  const {
    data: aggLogs,
    isLoading: isLogsLoading,
    refetch: refetchTrans,
    isRefetching,
  } = useGetSingleAggLog({
    path: email as string,
    queryParams: {
      page: page,
      limit: 20,
      filter: query ?? undefined,
    },
  });

  const { mutate: activateAggFn, isLoading: isActivating } = useActivateAgg({});
  const { mutate: deactivateAggFn, isLoading: isDeactivating } =
    useDeactivateAgg({});

  const handleActivation = () => {
    if (email) {
      deactivateAggFn({
        email: email,
      });
    } else
      activateAggFn({
        email: email,
      });
  };
  const handleExport = async () => {
    setIsExporting(true);
    await handleExportDownload(
      `aggregators/subagent-log-export/${email}`,
      `${email}-subagent-logs`,
      authToken!
    );
    setIsExporting(false);
  };  

  //=======making a query search after every 3 seconds provided there's a searched query======//
  const debouncedSearch = React.useRef(
    debounce(() => {
      refetchTrans();
    }, 300)
  ).current;
  useEffect(() => {
    setPage(1);

    if (query) {
      debouncedSearch();
    } else if (query.length === 0) {
      setPage(1);
      refetchTrans();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch, refetchTrans]);
  //==================================================================

  useEffect(() => {
    window.scrollTo(0, 0);
    refetchTrans();
  }, [page, refetchTrans]);

  const columns: TableColumn<SubAgentLog>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      cell: (row, index) => (
        <>
          {aggLogs?.meta.currentPage
            ? index + 1 + 10 * (aggLogs.meta.currentPage - 1)
            : ""}
        </>
      ),
      width: "70px",
    },
    {
      name: "Agent name",
      //   selector: (row) => row.transactionId,
      cell: (row) => <div className="text-black">{row.fullName}</div>,
    },
    {
      name: "Total Amount Earned",
      selector: (row) => row.totalAmountEarned,
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A `}
          value={row.totalAmountEarned}
        />
      ),
    },
    {
      name: "Commision",
      width: "17%",
      selector: (row) => row.totalCommission || 0,
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A`}
          value={row.totalCommission}
        />
      ),
    },
    {
      name: "Agent Code",
      selector: (row) => row.agentCode,
      cell: (row) => {
        return <>{row.agentCode}</>;
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          onClick={handleActivation}
          className={`mx-auto   font-productsans700 text-base disabled:opacity-30 ${
            true ? "text-red-600" : "text-green-700"
          }`}
          disabled={isActivating || isDeactivating}
        >
          {true ? "Deactivate" : "Activate"}
        </button>
      ),
    },
  ];

  if (isLogsLoading) return <ProgressBar />;
  return (
    <div className="flex flex-col space-y-6">      
      <div className="flex items-center justify-between">
        <input
          className="w-1/2 border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
          placeholder="Search with transaction number, amount or date"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="flex gap-2 ">
          <PlainButton title="Send Push" />
          <PlainButton title="Filter records" />
          <PlainButton
            title="Export"
            onClick={handleExport}
            disabled={isExporting}
            isLoading={isExporting}
          />
        </div>
      </div>
      <div className={`${isRefetching ? "opacity-30" : ""} space-y-7`}>
        <Table
          columns={columns}
          data={aggLogs == null ? [] : aggLogs?.content!}
        />
      </div>
      <div className="mb-6">
        <PaginationTab
          currentPage={page}
          setCurrentPage={setPage}
          totalPages={aggLogs?.meta.totalPages!}
        />
      </div>
    </div>
  );
};

export default AggregatorLog;
