import clsx from "classnames";
import { capitalizeText } from "components/utils/string";
import { newDropDownOptions } from "interfaces/transactions";
import { useEffect, useRef, useState } from "react";
import close from "../../../assets/icons/general/close.svg";
import { TransData } from "services/api/types";
import { useFilterAllTransactions } from "services/api/transactionPaths";
import { getNonEmptyFields } from "utils/functions";
import usePersistedState from "hooks/usePersistedState";
import { Loader2 } from "lucide-react";

interface TransactionFilterProps {
  visible: boolean;
  onClose: () => void;
  setTransData: React.Dispatch<React.SetStateAction<TransData | undefined>>;
  setApplyFilter: React.Dispatch<React.SetStateAction<boolean>>;
  applyFilter: boolean;
  page: number;
  setFilters: React.Dispatch<
    React.SetStateAction<{
      startDate: string;
      endDate: string;
      period: string;
      transactionType: string;
      transactionStatus: string;
      transactionSource: string;
      minBalance: string;
      maxBalance: string;
    }>
  >;
}

export default function TransactionFilter({
  visible,
  onClose,
  applyFilter,
  setApplyFilter,
  setFilters,
  setTransData,
  page,
}: TransactionFilterProps) {
  const formEmptyState = {
    startDate: "",
    endDate: "",
    period: "",
    transactionType: "",
    transactionStatus: "",
    transactionSource: "",
    minBalance: "",
    maxBalance: "",
  };
  const selectRef = useRef<HTMLSelectElement>(null);
  const [isApplyClicked, setIsApplyClicked] = useState(false);

  const [formState, setFormState] = usePersistedState({
    key: "transFilter",
    defaultValue: formEmptyState,
  });

  const nonEmptyFields = getNonEmptyFields(formState);

  const {
    data: filteredTrans,
    // isLoading,
    refetch: refetchFilteredTrans,
    isRefetching,
  } = useFilterAllTransactions({
    queryParams: { ...nonEmptyFields, page: page, limit: 20 },
    onSuccess: (data) => {
      if (applyFilter && !isRefetching && isApplyClicked) {
        setTransData(filteredTrans);
        // onClose()
      }
    },
  });

  const handleFiltering = () => {
    setApplyFilter(true);
    refetchFilteredTrans();
    setFilters(formState);
    setIsApplyClicked(true);
    // onClose()
  };

  useEffect(() => {
    refetchFilteredTrans();
    // window.scrollTo(0, 0);
  }, [page, refetchFilteredTrans]);
  useEffect(() => {
    // console.log(applyFilter, filteredTrans);
    if (applyFilter && !isRefetching && isApplyClicked) {
      setTransData(filteredTrans);
      onClose()
    }
  }, [
    refetchFilteredTrans,
    applyFilter,
    isRefetching,
    setTransData,
    filteredTrans,
  ]);
  return (
    <div
      role="presentation"
      onClick={onClose}
      className={clsx(
        "fixed top-0 left-0 z-[999] w-full h-screen opacity-0 pointer-events-none overflow-y-auto bg-black/[.6] flex items-center justify-center",
        {
          "!opacity-100 !pointer-events-auto": visible,
        }
      )}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white pt-8 py-[72px] px-[31px] pb-[50px] xl:w-[1081px] flex flex-col"
      >
        <div className="">
          <div className="flex items-center justify-between">
            <h1 className="text-lg font-bold mb-4">
              Filter transaction records
            </h1>

            <span
              className="p-3 bg-D9D9D9 rounded-full cursor-pointer"
              onClick={onClose}
            >
              <img src={close} alt="" />
            </span>
          </div>
          <div className="">
            <h1 className="text-lg font-bold mb-2">Custom date filter</h1>
            <div className="flex gap-4 items-end">
              <label htmlFor="startDate">
                <p>Start date</p>

                <input
                  className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                  placeholder="Select start date"
                  type="date"
                  name="startDate"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      startDate: e.target.value,
                      period: "",
                    })
                  }
                  value={formState.startDate}
                />
              </label>
              <p className="h-8">-</p>
              <label htmlFor="endDate">
                <p>End date</p>
                <input
                  className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                  placeholder="Select end date"
                  type="date"
                  name="endDate"
                  min={formState.startDate}
                  disabled={formState.startDate.length < 1}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      endDate: e.target.value,
                      period: "",
                    })
                  }
                  value={formState.endDate}
                />
              </label>
              <select
                ref={selectRef}
                // value={selectedOption}
                className="bg-[#F5F5F5] p-2 rounded-md h-10"
                value={formState.period}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    period: e.target.value,
                    startDate: "",
                    endDate: "",
                  })
                }
                name="period"
              >
                <option>Select duration</option>
                {newDropDownOptions.map((option, index) => (
                  <option value={option.value} key={index}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className=" mt-6">
            <h1 className="text-lg font-bold mb-2">Transaction volume</h1>
            <div className="flex gap-4 items-center">
              <input
                className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                placeholder="Enter min balance"
                type="number"
                name="minBalance"
                value={formState.minBalance}
                onChange={(e) =>
                  setFormState({ ...formState, minBalance: e.target.value })
                }
              />
              -
              <input
                className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                placeholder="Enter max balance"
                type="number"
                name="maxBalance"
                value={formState.maxBalance}
                onChange={(e) =>
                  setFormState({ ...formState, maxBalance: e.target.value })
                }
              />
            </div>
          </div>
          <div className="mt-6">
            <h1 className="text-lg font-bold mb-2">Transaction type</h1>
            <div className="flex gap-3 flex-wrap">
              {trans_type.map((type) => (
                <button
                  className="w-[150px] py-2 rounded-md disabled:opacity-40 h-10"
                  style={{
                    backgroundColor: `${
                      formState.transactionType === type.tag
                        ? "#273B4A"
                        : "#F5F5F5"
                    }`,
                    color: `${
                      formState.transactionType === type.tag
                        ? "#FFF"
                        : "#000000"
                    }`,
                  }}
                  onClick={() =>
                    setFormState({
                      ...formState,
                      transactionType:
                        type.tag === formState.transactionType ? "" : type.tag,
                    })
                  }
                >
                  {type.name}
                </button>
              ))}
            </div>
          </div>
          <div className="mt-6">
            <h1 className="text-lg font-bold mb-2">Transaction status</h1>
            <div className="flex gap-3 flex-wrap">
              {trans_status.map((status) => (
                <button
                  className="w-[150px] py-2 rounded-md disabled:opacity-40 h-10"
                  style={{
                    backgroundColor: `${
                      formState.transactionStatus === status
                        ? "#273B4A"
                        : "#F5F5F5"
                    }`,
                    color: `${
                      formState.transactionStatus === status
                        ? "#FFF"
                        : "#000000"
                    }`,
                  }}
                  onClick={() =>
                    setFormState({
                      ...formState,
                      transactionStatus:
                        status === formState.transactionStatus ? "" : status,
                    })
                  }
                >
                  {capitalizeText(status)}
                </button>
              ))}
            </div>
          </div>
          <div className="mt-6">
            <h1 className="text-lg font-bold mb-2">Transaction source</h1>
            <div className="flex gap-3 flex-wrap">
              {trans_source.map((source) => (
                <button
                  className="w-[150px] py-2 rounded-md disabled:opacity-40 h-10"
                  style={{
                    backgroundColor: `${
                      formState.transactionSource === source
                        ? "#273B4A"
                        : "#F5F5F5"
                    }`,
                    color: `${
                      formState.transactionSource === source
                        ? "#FFF"
                        : "#000000"
                    }`,
                  }}
                  onClick={() =>
                    setFormState({
                      ...formState,
                      transactionSource:
                        source === formState.transactionSource ? "" : source,
                    })
                  }
                >
                  {capitalizeText(source)}
                </button>
              ))}
            </div>
          </div>
          <div className="flex justify-end gap-3">
            <button
              className="ml-auto border border-[#273B4A] rounded-md py-2 px-4 hover:bg-[#273B4A] hover:text-white"
              onClick={() => {
                setFormState(formEmptyState);
                setApplyFilter(false)
                setTransData(undefined);
                refetchFilteredTrans();
                setFilters(formState);
                window.localStorage.removeItem("transFilter");
                onClose();
              }}
            >
              Clear filters
            </button>
            <button
              className="flex gap-2 items-center border border-[#273B4A] rounded-md py-2 px-4 hover:bg-[#273B4A] hover:text-white"
              onClick={handleFiltering}
            >
              Apply filters
              {isRefetching && isApplyClicked && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export const trans_type = [
  {
    name: "Inflow to wallet",
    tag: "walletInflow",
  },
  {
    name: "Inflow to Group",
    tag: "groupInflow",
  },
  {
    name: "Outflow to Wallet",
    tag: "walletOutflow",
  },
  {
    name: "Outflow to Group",
    tag: "groupOutflow",
  },
  {
    name: "Wallet to Group",
    tag: "walletToGroup",
  },
  {
    name: "Group to Wallet",
    tag: "groupToWallet",
  },
  {
    name: "Wallet to Wallet",
    tag: "walletToWallet",
  },
  {
    name: "Group to Group",
    tag: "groupToGroup",
  },
  {
    name: "Bill Payment",
    tag: "billPayment",
  },
];
export const trans_status = ["successful", "failed", "pending", "reversed"];
export const trans_source = ["wallet", "group"];
