import ModalWrapper from "components/utils/ModalWrapper";
import toast from "react-hot-toast";
import {
  useBlockAccount,
  useUnblockAccount,
} from "services/api/mutation-paths";
import { SingleUser } from "services/api/types";

type BlockProps = {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  blocked: boolean;
  setBlocked: React.Dispatch<React.SetStateAction<boolean>>;
  user: SingleUser;
};
const BlockUserModal = ({
  closeModal,
  blocked,
  setBlocked,
  user,
}: BlockProps) => {
  const { mutate: blockUser, isLoading: isBlocking } = useBlockAccount({
    onSuccess: () => {
      setBlocked(!blocked);
      toast.success("User blocked successfully");
    },
  });
  const { mutate: unBlockUser, isLoading: isUnblocking } = useUnblockAccount({
    onSuccess: () => {
      setBlocked(!blocked);
      toast.success("User unblocked successfully");
    },
  });
  const handleBlocking = () => {
    if (blocked) {
      unBlockUser({
        email: user.email,
      });
      // console.log(`unblocked ${user.email}`)
    } else {
      blockUser({
        email: user.email,
      });
      // console.log(`blocked ${user.email}`);

    }
  };

  return (
    <ModalWrapper>
      <div className="p-4">
        <div className="flex justify-end">
          <span
            className="block h-10 w-10 bg-D9D9D9 rounded-full cursor-pointer"
            onClick={() => closeModal(false)}
          ></span>
        </div>
        <div className="px-12">
          <div className="mt-5 mb-12">
            <h1 className="mb-2 text-xl">
              Are you sure you want to {blocked ? "unblock" : "block"} this
              user?
            </h1>
            <p>Name : {user.fullName}</p>
            <p>Email : {user.email}</p>
          </div>
          <div className="ml-auto mb-5">
            <button
              type="button"
              onClick={handleBlocking}
              disabled={isBlocking || isUnblocking}
              className="h-[41.44px] px-[17px] border border-D9D9D9 rounded-5 bg-FF0053 text-white text-[15px] font-productsans disabled:opacity-30"
            >
              {blocked ? "Unblock Account" : "Block Account"}
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default BlockUserModal;
