import { useContext } from "react";
import { AuthContext } from "./auth";
import { UserContext } from "./user";
import { DashboardContext } from "./dashboard";
import { TransContext } from "./transaction";


export const useAuthContext = () => useContext(AuthContext);
export const useUserContext = () => useContext(UserContext)
export const useTransContext = () => useContext(TransContext);
export const useDashboardContext = () => useContext(DashboardContext);
