import ModalWrapper from "components/utils/ModalWrapper";
import { useAuthContext } from "context";
import { Loader2 } from "lucide-react";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { handleExportDownload } from "utils/functions";

type ExportProps = {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function ExportTransModal({ closeModal }: ExportProps) {
  const { getValues, control, watch } = useForm();
  const [isExporting, setIsExporting] = useState(false);
  const { authToken } = useAuthContext();

  const handleExport = async () => {
    const startDate = getValues("startDate");
    const endDate = getValues("endDate");
    setIsExporting(true);
    await handleExportDownload(
      `transactions/export?startDate=${startDate}&endDate=${endDate}`,
      "all-transactions-cumulative",
      authToken!
    );
    setIsExporting(false);
    closeModal(false)
  };
  return (
    <ModalWrapper>
      <div className="p-4 w-[33rem]">
        <div className="flex justify-end">
          <span
            className="block h-10 w-10 bg-D9D9D9 rounded-full cursor-pointer"
            onClick={() => closeModal(false)}
          ></span>
        </div>
        <div className="px-12">
          <div className="mb-4">
            <h1 className="mb-2 text-xl">Select dates for export</h1>
          </div>
          <div className="flex items-center  w-[250px] justify-between">
            <p>Start date : </p>

            <Controller
              name="startDate"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <input
                  className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                  placeholder="Select start date"
                  {...field}
                  type="date"
                  step="1"
                  max={new Date().toISOString().split("T")[0]}
                  // onChange={(e) => setNewPin(e.target.value)}
                />
              )}
            />
          </div>
          <div className="flex items-center w-[250px] justify-between mt-3">
            <p>End date : </p>
            <Controller
              name="endDate"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <input
                  className="border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
                  placeholder="Select end date"
                  {...field}
                  type="date"
                  step="1"
                  min={watch("startDate")}
                  disabled={watch("startDate")?.length < 1}
                  max={new Date().toISOString().split("T")[0]}
                  // onChange={(e) => setNewPin(e.target.value)}
                />
              )}
            />
          </div>

          <div className="ml-auto mb-5 mt-8">
            <button
              type="button"
              //   onClick={()=>console.log(getValues('otp'))}
              onClick={handleExport}
              disabled={isExporting}
              className="h-[41.44px] px-[17px] flex gap-3 items-center justify-center border border-D9D9D9 rounded-5 bg-273B4A text-white text-[15px] font-productsans disabled:opacity-60"
            >
              Export transactions
              {isExporting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
