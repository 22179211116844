import React, { createContext, useState } from "react";

export type defaultDashboardProps = {
  personalParams: any;
  setPersonalParams: React.Dispatch<React.SetStateAction<any>>;
  groupParams: any;
  setGroupParams: React.Dispatch<React.SetStateAction<any>>;
  billParams: any;
  setBillParams: React.Dispatch<React.SetStateAction<any>>;
  personalActive: string;
  setPersonalActive: React.Dispatch<React.SetStateAction<string>>;
  groupActive: string;
  setGroupActive: React.Dispatch<React.SetStateAction<string>>;
  billActive: string;
  setBillActive: React.Dispatch<React.SetStateAction<string>>;
};

const defaultDashboardState: defaultDashboardProps = {
  personalParams: {
    cumulativePersonalWallet: "allTime",
    bankTransferInflowCount: "allTime",
    bankTransferInflowValue: "allTime",
    cardInflowCount: "allTime",
    cardInflowValue: "allTime",
    groupInflowCount: "allTime",
    groupInflowValue: "allTime",
    peerInflowCount: "allTime",
    peerInflowValue: "allTime",
    totalInflowValue: "allTime",
    totalInflowTransferFees: "allTime",
    totalInflowCardFees: "allTime",
    totalOutflowValue: "allTime",
    totalOutflowCount: "allTime",
    totalOutflowViaZenith: "allTime",
    totalOutflowViaFlutterwave: "allTime",
    totalOutflowFeesViaZenith: "allTime",
    totalOutflowFeesViaFlutterwave: "allTime",
    totalOutflowFees: "allTime",
    outflowToPeer: "allTime",
    outflowToGroup: "allTime",
    outflowFeesEarnedByGwala: "allTime",
    outflowFeesEarnedByAggregators: "allTime",
    outflowFeesEarnedByAgents: "allTime",
  },
  setPersonalParams: () => undefined,
  personalActive: "cumulativePersonalWallet",
  setPersonalActive: () => undefined,
  groupActive: "",
  setGroupActive: () => undefined,
  groupParams: {
    cumulativeGroupWallet: "allTime",
    bankTransferInflowCount: "allTime",
    bankTransferInflowValue: "allTime",
    cardInflowCount: "allTime",
    cardInflowValue: "allTime",
    personalWalletInflowCount: "allTime",
    personalWalletInflowValue: "allTime",
    groupToGroupInflowCount: "allTime",
    groupToGroupInflowValue: "allTime",
    totalInflowValue: "allTime",
    totalInflowTransferFees: "allTime",
    totalInflowCardFees: "allTime",
    totalOutflowValue: "allTime",
    totalOutflowCount: "allTime",
    totalOutflowViaZenith: "allTime",
    totalOutflowViaFlutterwave: "allTime",
    totalOutflowFeesViaZenith: "allTime",
    totalOutflowFeesViaFlutterwave: "allTime",
    totalOutflowFees: "allTime",
    outflowToPersonalWallets: "allTime",
    outflowToGroup: "allTime",
    outflowFeesEarnedByGwala: "allTime",
    outflowFeesEarnedByAggregators: "allTime",
    outflowFeesEarnedByAgents: "allTime",
  },
  setGroupParams: () => undefined,
  billActive: "",
  setBillActive: () => undefined,
  billParams: {
    billTransactionAmount: "allTime",
    billTransactionCount: "allTime",
    electricityValue: "allTime",
    electricityVolume: "allTime",
    airtimeValue: "allTime",
    airtimeVolume: "allTime",
    mobileDateValue: "allTime",
    mobileDateVolume: "allTime",
    dstvGotvValue: "allTime",
    dstvGotvVolume: "allTime",
    showMaxValue: "allTime",
    showMaxVolume: "allTime",
    airtimeEarnings: "allTime",
    dataTopupEarnings: "allTime",
    internetEarnings: "allTime",
    electricityEarnings: "allTime",
    dstvEarnings: "allTime",
    gotvEarnings: "allTime",
    showmaxEarnings: "allTime",
    cabletvEarnings: "allTime",
  },
  setBillParams: () => undefined,
};

export const DashboardContext = createContext(defaultDashboardState);

export const DashboardContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [personalActive, setPersonalActive] = useState<string>(
    "cumulativePersonalWallet"
  );
  const [groupActive, setGroupActive] = useState<string>(
    "cumulativeGroupWallet"
  );
  const [billActive, setBillActive] = useState<string>("billTransactionAmount");
  const [personalParams, setPersonalParams] = useState(
    defaultDashboardState.personalParams
  );
  const [groupParams, setGroupParams] = useState(
    defaultDashboardState.groupParams
  );
  const [billParams, setBillParams] = useState(
    defaultDashboardState.billParams
  );

  const value = {
    personalActive,
    personalParams,
    groupActive,
    groupParams,
    billActive,
    billParams,
    setPersonalActive,
    setPersonalParams,
    setGroupActive,
    setGroupParams,
    setBillActive,
    setBillParams,
  };
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
