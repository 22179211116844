import { CommaSeperatedNumber, PlainButton, Table } from "components/general";
import { TransactionType } from "interfaces/transactions";
import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import {
  useFilterAllTransactions,
  useGetAllTransactions,
} from "services/api/transactionPaths";
import { format } from "date-fns";
import { debounce } from "lodash";
import TransactionModal from "./transactionModal";
import { NA, defaultTrans } from "utils/constants";
import ProgressBar from "components/ProgressBar";
import PaginationTab from "components/general/PaginationBar";
import StatusColor from "components/utils/StatusColor";
import { capitalizeText } from "components/utils/string";
import ExportTransModal from "./exportTransModal";
import TransactionFilter from "components/general/FilterModals/transactionFilter";
import { TransData } from "services/api/types";
import { getNonEmptyFields } from "utils/functions";
import ExportTransModalTable from "./exportTransModalTable";
// import TransactionModal from "./transactionModal";

const TransactionTable = () => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = React.useState("");
  const [showExportCumModal, setShowExportCumModal] = React.useState(false);
  const [showExportTableModal, setShowExportTableModal] = React.useState(false);
  const [showTransactionModal, setShowTransactionModal] = React.useState(false);
  const [selectedTransaction, setSelectedTransaction] =
    useState<TransactionType>(defaultTrans);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [transData, setTransData] = useState<TransData>();
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    period: "",
    transactionType: "",
    transactionStatus: "",
    transactionSource: "",
    minBalance: "",
    maxBalance: "",
  });

  const {
    data: allTransData,
    isLoading: isTransDataLoading,
    refetch: refetchTrans,
    isRefetching,
  } = useGetAllTransactions({
    queryParams: {
      limit: 20,
      page: page,
      filter: query,
    },
  });

  const {
    data: filteredTrans,
    refetch: refetchFilteredTrans,
    isRefetching: isRefetchingFilteredTrans,
  } = useFilterAllTransactions({
    queryParams: { ...getNonEmptyFields(filters), page: page, limit: 20 },
    onSuccess: (data) => {
      if (applyFilter && !isRefetching) {
        setTransData(filteredTrans);
        // onClose()
      }
    },
  });

  const openTransactionDetails = (transaction: TransactionType) => {
    setSelectedTransaction(transaction);
    setShowTransactionModal(true);
  };

  const columns: TableColumn<TransactionType>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      sortable: false,
      cell: (row, index) => (
        <>
          {filteredTrans?.meta.currentPage ?? allTransData?.meta.currentPage
            ? index +
              1 +
              20 *
                ((applyFilter
                  ? filteredTrans?.meta.currentPage!
                  : allTransData?.meta.currentPage!) -
                  1)
            : ""}
        </>
      ),
      width: "75px",
    },
    {
      name: "Debit From",
      width: "19%",
      cell: (row) => (row.sender.length < 1 ? NA : row.sender),
    },
    {
      name: "Status",
      width: "150px",
      cell: (row) => (
        <div className="mx-auto">
          {row.transactionStatus === "Failed" ? (
            <div className="bg-[#FFACC7] h-5 flex items-center justify-center px-3 rounded-50">
              <span className="font-productsans text-[12px] text-273B4A">
                Failed
              </span>
            </div>
          ) : row.transactionStatus === "Success" ? (
            <div className="bg-E5FDD1 h-5 flex items-center justify-center px-3 rounded-50">
              <span className="font-productsans text-[12px] text-273B4A">
                Successful
              </span>
            </div>
          ) : (
            <>
              <div className="mx-auto">
                <StatusColor status={row.transactionStatus} />
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      name: "ID",
      width: "9%",
      selector: (row) => row.transactionId,
    },
    {
      name: "Type",
      width: "100px",
      cell: (row) => (
        <div className="mx-auto">
          {capitalizeText(row.transactionOperationType)}
        </div>
      ),
    },
    {
      name: "Amount",
      width: "14%",
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A`}
          value={row.amount}
        />
      ),
    },
    {
      name: "Transaction Date",
      width: "17%",
      selector: (row) => format(new Date(row.transactionDate), "MMMM d, yyyy"),
      // cell: (row) => <>here</>,
    },
    {
      name: "Action",
      width: "140px",

      cell: (row) => (
        <button
          onClick={() => openTransactionDetails(row)}
          className="mx-auto text-0067B4 font-productsans700 text-base"
        >
          View Details
        </button>
      ),
    },
  ];

  const nonEmptyFields = Object.entries(filters!).filter(
    ([key, value]) => value !== ""
  );
  //=======making a query search after every 3 seconds provided there's a searched query======//
  const debouncedSearch = React.useRef(
    debounce(() => {
      refetchTrans();
    }, 300)
  ).current;

  useEffect(() => {
    setPage(1);

    if (query) {
      setApplyFilter(false);
      debouncedSearch();
    } else if (query.length === 0) {
      setPage(1);
      refetchTrans();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch, refetchTrans]);
  //==================================================================
  useEffect(() => {
    if (!applyFilter) {
      refetchTrans();
    } else {
      setQuery("");
      refetchFilteredTrans();
    }
    window.scrollTo(0, 0);
  }, [page, refetchTrans, filters]);
  if (isTransDataLoading) return <ProgressBar />;
  return (
    <>
      {showTransactionModal && (
        <TransactionModal
          visible={showTransactionModal}
          onClose={() => setShowTransactionModal(false)}
          data={selectedTransaction}
        />
      )}
      {openFilter && (
        <TransactionFilter
          onClose={() => setOpenFilter(false)}
          visible={openFilter}
          setTransData={setTransData}
          setApplyFilter={setApplyFilter}
          applyFilter={applyFilter}
          setFilters={setFilters}
          page={page}
        />
      )}
      <div className="">
        <div className="mt-16">
          <div className="flex items-center justify-between">
            <input
              className="w-1/2 border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
              placeholder="Search keywords, titles or phrases"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <div className="flex gap-3">
              <PlainButton
                title="Filter records"
                onClick={() => setOpenFilter(true)}
              />
              <PlainButton
                title="Export Cumulative"
                onClick={() => setShowExportCumModal(true)}
              />
              <PlainButton
                title="Export Table"
                onClick={() => setShowExportTableModal(true)}
              />
            </div>
          </div>
        </div>
        <div className="mt-5">
          {nonEmptyFields.length > 0 && applyFilter && (
            <>
              <h1>Selected filters</h1>
              <div className="flex gap-2 flex-wrap">
                {nonEmptyFields.map(([key, value]) => (
                  <p key={key}>
                    <strong>{key}:</strong> {value}
                  </p>
                ))}
              </div>
            </>
          )}
        </div>
        <div
          className={`mt-6 space-y-9 ${
            isRefetching || isRefetchingFilteredTrans ? "opacity-30" : ""
          } space-y-8`}
        >
          <Table
            columns={columns}
            data={
              applyFilter ? filteredTrans?.content! : allTransData?.content!
            }
          />
        </div>
        <div className="mb-6">
          <PaginationTab
            currentPage={page}
            setCurrentPage={setPage}
            totalPages={
              applyFilter
                ? filteredTrans?.meta.totalPages!
                : allTransData?.meta.totalPages!
            }
          />
        </div>
      </div>
      {showExportCumModal && (
        <ExportTransModal closeModal={setShowExportCumModal} />
      )}
      {showExportTableModal && (
        <ExportTransModalTable closeModal={setShowExportTableModal} />
      )}
    </>
  );
};

export default TransactionTable;
