import React from "react";
import ResponsivePagination from "react-responsive-pagination";

// import "react-responsive-pagination/themes/classic.css";
import "react-responsive-pagination/themes/bootstrap.css";
// ^ classic theme, see below for other themes
// include this once in the project (preferrably the main index.js)
type Props = {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  totalPages: number;
};

export default function PaginationTab({ setCurrentPage, currentPage, totalPages } : Props) {

    function handlePageChange(page : number) {
      setCurrentPage(page);
      // ... do something with `page`
    }
  
  return (
    <ResponsivePagination
      total={totalPages}
      current={currentPage}
      onPageChange={(page) => handlePageChange(page)}
    />
  );
}
