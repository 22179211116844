import ProgressBar from "components/ProgressBar";
import RegisterAggregator from "components/aggregators/RegisterAggregator";
import { CommaSeperatedNumber, PlainButton, Table } from "components/general";
import PaginationTab from "components/general/PaginationBar";
import StatsCard from "components/general/StatsCard";
import { Wrapper } from "components/navigation";
import { useAuthContext } from "context";
import { newDropDownOptions } from "interfaces/transactions";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useGetAggregatorStats, useGetAllAggregators } from "services/api/path";
import { SingleAgg } from "services/api/types";
import { NA } from "utils/constants";
import { handleExportDownload } from "utils/functions";

export default function AggregatorList() {
  const navigate = useNavigate();
  const [query, setQuery] = React.useState("");
  const [page, setPage] = useState(1);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const { authToken } = useAuthContext();
  const [isExporting, setIsExporting] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [userVerified, setUserVerified] = useState("verifiedUsers");

  const [usersAggParams, setAggStatsParams] = useState({
    totalAggregator: "allTime",
    totalSubAgents: "allTime",
  });

  const {
    data: allAggregators,
    isLoading: isLoadingAggregators,
    isRefetching,
    refetch :refetchAgg
  } = useGetAllAggregators({
    queryParams: {
      page: 1,
      limit: 20,
      filter : query
    },
  });

  const {
    data: allAggregatorStats,
    isLoading: isLoadingStats,
    isRefetching: isRefetchingStats,
    refetch: refetchStats,
  } = useGetAggregatorStats({
    queryParams: usersAggParams,
  });

  const columns: TableColumn<SingleAgg>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      sortable: false,
      cell: (row, index) => (
        <>
          {allAggregators?.meta.currentPage
            ? index + 1 + 20 * (allAggregators.meta.currentPage - 1)
            : ""}
        </>
      ),
      width: "70px",
    },
    {
      name: "USER FULL NAME",
      selector: (row) => row.fullname,
    },
    {
      name: "TOTAL SUB-AGENTS",
      width: "16%",
      selector: (row) => row.totalSubAgents,
    },
    {
      name: "LAST LOGIN",
      width: "130px",
      cell: (row) => (
        <span className="font-productsans text-base text-273B4A">
          {row.lastLoginDate
            ? moment(row.lastLoginDate).format("DD/MM/YY")
            : NA}
        </span>
      ),
    },
    {
      name: "TOTAL EARNINGS",
      selector: (row) => row.paidCommission || 0,
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A`}
          value={row.paidCommission}
        />
      ),
    },
    {
      name: "UNPAID COMMISSIONS",
      width: "17%",
      selector: (row) => row.unpaidCommission || 0,
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A`}
          value={row.unpaidCommission}
        />
      ),
    },
    {
      name: "ACTION",
      cell: (row) => (
        <button
          onClick={() => {
            // setSelectedUser(row);
            navigate(`/aggregators/${row.email}`);
          }}
          // disabled={!row.isVerified}
          className="mx-auto text-0067B4 font-productsans700 text-base disabled:opacity-30 cursor-pointer disabled:cursor-not-allowed"
        >
          View details
        </button>
      ),
    },
  ];

  const handleParamsChange = (val: string, name: string) => {
    if (name === "totalSubAgents" || name === "totalAggregator") {
      setAggStatsParams({
        ...usersAggParams,
        [name]: val,
      });
    } else {
      setUserVerified(val);
    }
  };
  const handleSelectedRowsChange = (x: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => {
    setSelectedRows(x.selectedRows);
  };
  

  const handleExport = async () => {
    setIsExporting(true);
    await handleExportDownload(
      "aggregators/export",
      "all-aggregators",
      authToken!
    );
    setIsExporting(false);
  };

  //=======making a query search after every 3 seconds provided there's a searched query======//
  const debouncedSearch = React.useRef(
    debounce(() => {
      refetchAgg();
    }, 300)
  ).current;

  useEffect(() => {
    setPage(1);

    if (query) {
      debouncedSearch();
    } else if (query.length === 0) {
      setPage(1);
      refetchAgg();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch, refetchAgg]);
  //==================================================================
  useEffect(() => {
    refetchStats();
  }, [usersAggParams, refetchStats]);

  if (isLoadingAggregators || isLoadingStats)
    return (
      <Wrapper>
        <ProgressBar />
      </Wrapper>
    );

  return (
    <Wrapper>

      <RegisterAggregator
        visible={openRegisterModal}
        onClose={() => setOpenRegisterModal(false)}
      />
      {/* {openFilter && (
        <TransactionFilter
          onClose={() => setOpenFilter(false)}
          visible={openFilter}
        />
      )} */}
      <div
        className={`flex gap-[14px] flex-wrap ${
          isRefetchingStats ? "opacity-30" : ""
        }`}
      >
        <StatsCard
          header="TOTAL AGGREGATORS"
          dropDownOptions={newDropDownOptions}
          num={allAggregatorStats?.aggregatorCount.value!}
          text={usersAggParams.totalAggregator}
          percent={allAggregatorStats?.aggregatorCount.change.toFixed(2)!}
          decimal={false}
          name="totalAggregator"
          handleSelect={handleParamsChange}
        />

        <StatsCard
          header="TOTAL SUB-AGENTS"
          dropDownOptions={newDropDownOptions}
          num={allAggregatorStats?.subAgentCount.value!}
          text={usersAggParams.totalSubAgents}
          percent={
            allAggregatorStats?.subAgentCount.change
              ? allAggregatorStats?.subAgentCount.change.toFixed(2)!
              : "0"
          }
          name="totalSubAgents"
          decimal={false}
          width={312}
          handleSelect={handleParamsChange}
        />
        <StatsCard
          header="TOTAL ONBOARDED USERS"
          dropDownOptions={[
            { name: "Verified users", value: "verifiedUsers" },
            { name: "Unverified users", value: "unverifiedUsers" },
          ]}
          num={
            userVerified === "verifiedUsers"
              ? allAggregatorStats?.totalVerifiedUsers!
              : allAggregatorStats?.totalUnVerifiedUsers!
          }
          name="totalOnboardedUsers"
          decimal={false}
          // width={312}
          handleSelect={handleParamsChange}
        />
      </div>
      <div className="mt-[59px] flex flex-col space-y-[25px]">
        <div className="flex flex-col md:flex-row items-center gap-4 justify-between">
          <input
            className="w-full md:w-1/2 border border-D9D9D9 rounded-5 px-4 truncate outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
            placeholder="Search user by full name, email address or phone number"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="flex gap-3 flex-wrap">                      
            <PlainButton
              title="Register Aggregator"
              onClick={() => setOpenRegisterModal(true)}
            />
            <PlainButton
              title="Export"
              onClick={handleExport}
              isLoading={isExporting}
              disabled={isExporting}
            />
          </div>
        </div>
        <div className={`${isRefetching ? "opacity-30" : ""} space-y-8`}>
          <Table
            columns={columns}
            data={allAggregators?.content!}
            handleSelectedRowsChange={handleSelectedRowsChange}
          />
        </div>
        <div className="mb-6">
          <PaginationTab
            currentPage={page}
            setCurrentPage={setPage}
            totalPages={allAggregators?.meta.totalPages!}
          />{" "}
        </div>
      </div>
    </Wrapper>
  );
}
