import { CommaSeperatedNumber } from 'components/general';
import { GwalaUserPurchase } from 'interfaces/users';
import React from 'react';
import clsx from 'classnames';

const purchases: GwalaUserPurchase[] = [
	{
		name: 'Product 1',
		receiver_name: 'John',
		price: 50,
		tag: 'Experience',
		status: 'Redeemed',
	},
	{
		name: 'Product 2',
		receiver_name: 'Sarah',
		price: 30,
		tag: 'Gift Wallet',
		status: 'Unused',
	},
	{
		name: 'Product 3',
		receiver_name: 'Michael',
		price: 20,
		tag: 'Experience',
		status: 'Unused',
	},
	{
		name: 'Product 4',
		receiver_name: 'Emily',
		price: 100,
		tag: 'Gift Wallet',
		status: null,
	},
	{
		name: 'Product 5',
		receiver_name: 'David',
		price: 75,
		tag: 'Experience',
		status: 'Unused',
	},
	{
		name: 'Product 6',
		receiver_name: 'Emma',
		price: 40,
		tag: 'Gift Wallet',
		status: 'Redeemed',
	},
	{
		name: 'Product 7',
		receiver_name: 'Daniel',
		price: 60,
		tag: 'Experience',
		status: null,
	},
	{
		name: 'Product 8',
		receiver_name: 'Olivia',
		price: 25,
		tag: 'Gift Wallet',
		status: 'Unused',
	},
	{
		name: 'Product 9',
		receiver_name: 'James',
		price: 90,
		tag: 'Experience',
		status: 'Redeemed',
	},
	{
		name: 'Product 10',
		receiver_name: 'Sophia',
		price: 50,
		tag: 'Gift Wallet',
		status: 'Unused',
	},
];

const UserPurchase = ({ purchase }: { purchase: GwalaUserPurchase }) => {
	return (
		<div className="border border-C4C4C4 rounded-5 min-h-[114px] px-[14px] pt-[17px] pb-[11px]">
			<h3 className="text-[30px] font-productsans text-black mb-[10px] leading-none">
				{purchase.receiver_name}
			</h3>
			<p className="mb-[9px] text-545454 text-[17px] font-productsans">
				Purchased for {purchase.receiver_name}
			</p>
			<div className="flex space-x-2">
				<div className="h-[24px] flex items-center justify-center bg-D9D9D9 px-3 rounded-50">
					<CommaSeperatedNumber
						value={36000}
						hasDecimalPlaces={false}
						className="font-productsans text-[17px]"
					/>
				</div>
				<div className="h-[24px] flex items-center justify-center bg-0067B4 px-3 rounded-50">
					<span className="font-productsans text-[17px] text-white">
						{purchase.tag}
					</span>
				</div>
				{purchase.status && (
					<div
						className={clsx(
							'text-[17px] font-productsans px-3 rounded-50 h-[24px] flex items-center justify-center',
							{
								'bg-E5FDD1': purchase.status === 'Redeemed',
								'bg-FFACC7': purchase.status === 'Unused',
							}
						)}>
						<span className="font-productsans text-[17px] text-black">
							{purchase.status}
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

const UserPurchases = () => {
	return (
		<div className="grid grid-cols-3 gap-[21px]">
			{purchases.map((purchase, index) => (
				<UserPurchase purchase={purchase} key={index} />
			))}
		</div>
	);
};

export default UserPurchases;
