import ProgressBar from 'components/ProgressBar';
import { useDashboardContext } from 'context';
import React, { useEffect, useMemo } from 'react'
import { useGetBillPaymentsStats } from 'services/api/transactionPaths';
import { DashboardBillPayment } from 'services/api/types';
import DashboardCard from '../cards/dashboardCardBill';

export default function BillPaymentTab() {
  const { billParams } = useDashboardContext();

  const {
    data: billPaymentStats,
    refetch: refetchStats,
    isLoading,
    isRefetching,
  } = useGetBillPaymentsStats({
    queryParams: billParams,
  });

  const billPaymentStatsData = useMemo(
    () => billPaymentStats ?? {},
    [billPaymentStats]
  );
  useEffect(() => {
    refetchStats();
  }, [billParams, refetchStats]);

  if (isLoading) return <ProgressBar />;
  return (
    <div className="grid grid-cols-4 col-span-4 justify-between gap-6 mb-8">
      {Object.keys(billPaymentStatsData as DashboardBillPayment).map(
        (key, i) => (
          <DashboardCard
            num={i}
            name={`${key}`}
            deets={billPaymentStats!}
            refetching={isRefetching}
            key={i}
          />
        )
      )}
    </div>
  );
}
