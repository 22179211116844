export type TransactionStatus = "Pending" | "Successful";

export interface TransactionTableType {
  debit_from: string;
  status: TransactionStatus;
  trans_id: string;
  amount: number;
  trans_date: string;
}

export type TransactionType = {
  transactionId: number;
  amount: number;
  transactionType: string;
  transactionStatus: string;
  transactionChannel: string;
  transactionDate: string;
  productDescription: string;
  referenceId: null;
  paymentId: null;
  paymentSources: string;
  productName: null;
  fullName: string;
  sender: string;
  receiver: string;
  transactionOperationType: string;
};

export const dropDownOptions = [
  "All time",
  "Last Week",
  "Today",
  "30 days",
  "60 days",
];
export const newDropDownOptions = [
  {
    name: "All time",
    value: "allTime",
  },
  {
    name: "Last Week",
    value: "lastWeek",
  },
  {
    name: "Today",
    value: "today",
  },
  {
    name: "30 days",
    value: "30days",
  },
  {
    name: "60 days",
    value: "60days",
  },
];

export const personalWalletTexts = [
  "Cumulative personal wallet",
  "Inflow count via bank transfers",
  "Inflow value via bank transfer",
  "Inflow count via card",
  "Inflow value via card",
  "Inflow count from groups",
  "Inflow value via group",
  "Inflow count for p2p",
  "Inflow value via p2p",
  "Total transaction inflow",
  "Total inflow fees via transfer",
  "Total inflow fees via card",
  "Total outflow value",
  "Total outflow count",
  "Total outflow via Zenith",
  "Total outflow via Flutterwave",
  "Total outflow fees via Zenith",
  "Total outflow fees via Flutterwave",
  "Total outflow fees",
  "Total outflow to peer",
  "Total outflow to group",
  "Outflow fees earned by Gwala",
  "Outflow fees earned by Aggregators",
  "Outflow fees earned by Agents",
];

export const groupWalletTexts = [
  "Cumulative group wallet",
  "Inflow count via bank transfers",
  "Inflow value via bank transfer",
  "Inflow count via card",
  "Inflow value via card",
  "Inflow count from personal wallet",
  "Inflow value via personal wallet",
  "Inflow count for group-to-group",
  "Inflow value via group-to-group",
  "Total transaction inflow",
  "Total inflow fees via transfer",
  "Total inflow fees via card",
  "Total outflow value",
  "Total outflow count",
  "Total outflow via Zenith",
  "Total outflow via Flutterwave",
  "Total outflow fees via Zenith",
  "Total outflow fees via Flutterwave",
  "Total outflow fees",
  "Total outflow to personal wallets",
  "Total outflow to group",
  "Outflow fees earned by Gwala",
  "Outflow fees earned by Aggregators",
  "Outflow fees earned by Agents",
];

export const billPaymentTexts = [
  "Total bill transaction",
  "Transaction count",
  "Electricity total value",
  "Electricity total volume",
  "Airtime total value",
  "Airtime total volume",
  "Mobile data total value",
  "Mobile data total volume",
  "DSTV & GOTV total value",
  "DSTV & GOTV total volume",
  "Showmax total value",
  "Showmax total volume",
  "Airtime Earnings",
  "Data topup earnings",
  "Internet Earnings",
  "Electricity Earnings",
  "Dstv Earnings",
  "Gotv Earnings",
  "Showmax Earnings",
  "CableTv Earnings",
];

export enum TransStatus {
  "Success" = 0,
  "Pending" = 1,
  "Failed" = 2,
  "Error" = 3,
  "Awaiting Approval" = 4,
}

export enum TransChannel {
  "Flutterwave" = 0,
  "Paystack" = 1,
  "Strip" = 2,
  "Offline" = 3,
  "Monnify" = 4,
  "Zenith" = 5,
  "PrimeAirtime" = 6,
  "Zenith Transfer" = 7,
}

export enum TransType {
  "UserWallet Payment" = 0,
  "UserWallet Withdrawal" = 1,
  "GroupWallet Payment" = 2,
  "GroupWallet Withdrawal" = 3,
  "Airtime TopUp" = 4,
  "Data TopUp" = 5,
  "Electricity TopUp" = 6,
  "Dstv TopUp" = 7,
  "GoTv TopUp" = 8,
  "Internet TopUp" = 9,
  "ShowMax TopUp" = 10,
  "CableTv TopUp" = 11,
}
