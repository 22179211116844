import ProgressBar from "components/ProgressBar";
import { CommaSeperatedNumber, Table } from "components/general";
import PaginationTab from "components/general/PaginationBar";
import { format } from "date-fns";
import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useGetSingleUserGroups } from "services/api/path";
import { UserGroupData } from "services/api/types";

type GroupsProps = {
  email: string;
};

const UserGroups = ({ email} : GroupsProps) => {
   const [page, setPage] = useState(1);

   const {
     data: userGroups,
     isLoading: isGroupsLoading,
   } = useGetSingleUserGroups({
     path: email as string,
     queryParams: {
       page: page,
       limit: 20,
     },
   });

    const columns: TableColumn<UserGroupData>[] = [
      {
        name: "S/N",
        selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
        cell: (row, index) => (
          <>
            {userGroups?.meta.currentPage
              ? index + 1 + 10 * (userGroups.meta.currentPage - 1)
              : ""}
          </>
        ),
        width: "70px",
      },
      {
        name: "Group Name",
        selector: (row) => row.groupName,
        sortable: true,
      },
      {
        name: "Admin Status",
        width: "175px",
        // selector: (row) => row.admin_status,
        cell: (row) => (
          <div className="mx-auto">
            {row.isAdmin ? (
              <div className="bg-[#F4CF4A] h-5 flex items-center justify-center px-3 rounded-50">
                <span className="font-productsans text-[12px] text-273B4A">
                  Group Admin
                </span>
              </div>
            ) : (
              <div className="bg-E5FDD1 h-5 flex items-center justify-center px-3 rounded-50">
                <span className="font-productsans text-[12px] text-273B4A">
                  Member
                </span>
              </div>
            )}
          </div>
        ),
      },
      {
        name: "Contribution",
        sortable: true,
        cell: (row) => (
          <CommaSeperatedNumber
            className={`font-productsans text-base text-273B4A`}
            value={row.amountContributed}
          />
        ),
      },
      {
        name: "Date Joined",
        sortable: true,
        selector: (row) => format(new Date(row.dateJoined), "MMMM d, yyyy"),
      },
    ];

  if (isGroupsLoading) return <ProgressBar />;

  return (
    <div className="w-4/5 flex flex-col space-y-6">
      <Table
        columns={columns}
        data={userGroups?.content!}
        noDataComponentDescription="This user is yet to create or join any group"
        noDataComponentHeader="No Groups yet"
      />
      <div className="mb-6">
        <PaginationTab
          currentPage={page}
          setCurrentPage={setPage}
          totalPages={userGroups?.meta.totalPages!}
        />
      </div>
    </div>
  );
};

export default UserGroups;
