import { GwalaUser2 } from "interfaces/users";
import React, { createContext, useEffect, useState } from "react";
import { Allusers } from "services/api/types";

// type SortColumn = { id: string | undefined; desc: boolean | undefined };

export type defaultUserProps = {
  selectedUser: GwalaUser2 | undefined;
  setSelectedUser: React.Dispatch<React.SetStateAction<GwalaUser2 | undefined>>;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageNumberClicked: boolean;
  setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterOn: boolean;
  setIsFilterOn: React.Dispatch<React.SetStateAction<boolean>>;
  allUsersFiltered: Allusers | undefined;
  setAllUsersFiltered: React.Dispatch<
    React.SetStateAction<Allusers | undefined>
  >;
};

const defaultUserState: defaultUserProps = {
  selectedUser: undefined,
  setSelectedUser: () => undefined,
  pageNumber: 1,
  setPageNumber: () => undefined,
  pageNumberClicked: false,
  setPageNumberClicked: () => undefined,
  isFilterOn: false,
  setIsFilterOn: () => undefined,
  allUsersFiltered: undefined,
  setAllUsersFiltered: () => undefined,
};

export const UserContext = createContext(defaultUserState);

export const UserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedUser, setSelectedUser] = useState<GwalaUser2 | undefined>();
  const [allUsersFiltered, setAllUsersFiltered] = useState<
    Allusers | undefined
  >();
  const [pageNumberClicked, setPageNumberClicked] = useState(false);
  const [isFilterOn, setIsFilterOn] = useState(false);

  useEffect(() => {
    setSelectedUser(selectedUser);
  }, [selectedUser]);
  const value = {
    pageNumber,
    setPageNumber,
    selectedUser,
    setSelectedUser,
    pageNumberClicked,
    setPageNumberClicked,
    isFilterOn,
    setIsFilterOn,
    allUsersFiltered,
    setAllUsersFiltered,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
