import usePersistedState from "hooks/usePersistedState";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useState,
} from "react";
import { keyAdmin } from "services/auth/path";
import { User } from "services/auth/types";
import { queryClient } from "services/queryClient";

export type AuthContextDefaultTypes = {
  user: User | undefined | null;
  setUser: Dispatch<SetStateAction<User | null | undefined>>;
  loggedIn: boolean;
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
  authToken: string | undefined;
  setAuthToken: Dispatch<SetStateAction<string>>;
  loginUser: (arg: { user: User }) => void;
  logoutUser: () => void;
};

const defaultValues: AuthContextDefaultTypes = {
  user: undefined,
  loggedIn: false,
  setLoggedIn: () => undefined,
  setUser: () => undefined,
  authToken: undefined,
  logoutUser: () => undefined,
  loginUser: () => undefined,
  setAuthToken: () => undefined,
};

export const AuthContext = createContext(defaultValues);

export default function AuthContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [loggedIn, setLoggedIn] = usePersistedState({
    key: "loggedIn",
    defaultValue: false,
  });
  // const [user, setUser] = usePersistedState({
  //   key: "user",
  //   defaultValue: undefined,
  // });
  const [user, setUser] = useState<User | null>();

  /**
   * defaultValue:  {
    adminId: 0,
    fullName: 'string',
    email: '',
    role: 'string',
}
   */
  const [authToken, setAuthToken] = usePersistedState({
    key : 'authToken',
    defaultValue : ''
  });

  //   const { data, isLoading } = useLoginAdmin();

  const logoutUser = useCallback(() => {
    setLoggedIn(false);
    setUser(null);

    // queryClient.invalidateQueries(['/me'])
    queryClient.removeQueries(keyAdmin);
  }, [setLoggedIn, setUser]);

  const loginUser = useCallback(
    ({ user }: { user: User }) => {
      setUser(user);
    },
    [setUser]
  );

  //   useEffect(() => {
  //     if (!isLoading) {
  //       if (data) {
  //         loginUser({
  //           user: data,
  //         });
  //       } else {
  //         logoutUser();
  //       }
  //     }
  //   }, [
  //     data,
  //     setUser,
  //     isLoading,
  //     setLoggedIn,
  //     loginUser,
  //     logoutUser,
  //   ]);

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        user,
        setUser,
        setLoggedIn,
        loginUser,
        logoutUser,
        authToken,
        setAuthToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
