import { CommaSeperatedNumber } from "components/general";
import StyledSelect from "components/general/StyledSelect copy";
import { useDashboardContext } from "context";
import { billPaymentTexts, newDropDownOptions } from "interfaces/transactions";
import {
    DashboardBillPayment,
} from "services/api/types";
import { statsDetText } from "utils/functions";

type DashboardCardProps = {
  handleParamsChange?: (val: string, name: string) => void;
  num: number;
  name: string;
  deets: DashboardBillPayment;
  refetching: boolean;
};

const DashboardCard = ({
  num,
  name,
  deets,
  refetching,
}: DashboardCardProps) => {
  const { billActive, setBillActive, setBillParams, billParams } =
    useDashboardContext();
  const handleParamsChange = (val: string, name: string) => {
    setBillParams({
      ...billParams,
      [name]: val,
    });
  };
  const singleDeet = deets[name as keyof typeof deets];
  return (
    <div
      className={` rounded-2xl ${
        billActive === name ? "bg-[#FF947A]" : "bg-[#F5F5F5]"
      } pl-[17px] pt-[30px] pb-[25px] ${
        refetching && billActive === name && "opacity-30"
      }`}
      onClick={() => setBillActive(name)}
    >
      <StyledSelect
        options={newDropDownOptions}
        handleSelect={handleParamsChange}
        active={billActive === name}
        name={name}
      />
      <p className="m-0 mt-4 h-0">&nbsp;</p>
      <CommaSeperatedNumber
        value={singleDeet.value}
        className={`${
          billActive === name ? "text-white" : "text-[#151D48]"
        } font-mclaren text-2xl`}
        hasDecimalPlaces={
          !(
            billPaymentTexts[num].includes("count") ||
            billPaymentTexts[num].includes("volume")
          )
        }
      />
      <h2
        className={`${
          billActive === name ? "text-white" : "text-[#425166]"
        } text-lg mb-[7px]`}
      >
        {billPaymentTexts[num]}
      </h2>
      <span
        className={`${
          billActive === name ? "text-white" : "text-[#4079ED]"
        } text-xs font-medium`}
      >
        {singleDeet.change ?? 0}% {statsDetText(billParams[name])}
      </span>
    </div>
  );
};

export default DashboardCard;
