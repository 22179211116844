import { CommaSeperatedNumber } from "components/general";
import StyledSelect from "components/general/StyledSelect copy";
import { useDashboardContext } from "context";
import { newDropDownOptions, personalWalletTexts } from "interfaces/transactions";
import { DashboardGroupWallet, DashboardPersonalWallet } from "services/api/types";
import { statsDetText } from "utils/functions";

type DashboardCardProps = {
  handleParamsChange?: (val: string, name: string) => void;
  num: number;
  name: string;
  deets: DashboardPersonalWallet | DashboardGroupWallet;
  refetching: boolean;
};

const DashboardCard = ({ num, name, deets, refetching }: DashboardCardProps) => {
  const { personalActive, setPersonalActive, setPersonalParams, personalParams } = useDashboardContext();
  const handleParamsChange = (val: string, name: string) => {
    setPersonalParams({
      ...personalParams,
      [name]: val,
    });
  };
  const singleDeet = deets[name as keyof typeof deets]
  return (
    <div
      className={` rounded-2xl ${
        personalActive === name ? "bg-[#00C514]" : "bg-[#F5F5F5]"
      } pl-[17px] pt-[30px] pb-[25px] ${
        refetching && personalActive === name && "opacity-30"
      }`}
      onClick={() => setPersonalActive(name)}
    >
      <StyledSelect
        options={newDropDownOptions}
        handleSelect={handleParamsChange}
        active={personalActive === name}
        name={name}
      />
      <p className="m-0 mt-4 h-0">&nbsp;</p>
      <CommaSeperatedNumber
        value={singleDeet.value}
        className={`${
          personalActive === name ? "text-white" : "text-[#151D48]"
        } font-mclaren text-2xl`}
        //   hasDecimalPlaces={decimal}
        hasDecimalPlaces={!personalWalletTexts[num].includes("count")}
      />
      <h2
        className={`${
          personalActive === name ? "text-white" : "text-[#425166]"
        } text-lg mb-[7px]`}
      >
        {personalWalletTexts[num]}
      </h2>
      <span
        className={`${
          personalActive === name ? "text-white" : "text-[#4079ED]"
        } text-xs font-medium`}
      >
        {singleDeet.change ?? 0}% {statsDetText(personalParams[name])}
      </span>
    </div>
  );
};

export default DashboardCard;
