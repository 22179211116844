import { Loader2 } from "lucide-react";
import React from "react";

interface PlainButtonProps {
  title: string;
  onClick?: (_: any) => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const PlainButton = ({ title, onClick, disabled, isLoading }: PlainButtonProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className="h-[41.44px] px-[17px] border border-D9D9D9 rounded-5 bg-white text-273B4A text-xs md:text-[15px] font-productsans flex gap-3 items-center justify-center disabled:opacity-50" 
    >
      {title}
      {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
    </button>
  );
};

export default PlainButton;
