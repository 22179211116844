import React from 'react';
import SideNav from './SideNav';
import { handleClearLocalStorage } from 'services/auth';
import { useNavigate } from 'react-router-dom';
import { routes } from 'utils/constants';
import { useAuthContext } from 'context';

interface WrapperProps {
	children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => {
	const {setLoggedIn, logoutUser} = useAuthContext()
	const navigate = useNavigate()
	const handleLogout= () => {
		handleClearLocalStorage()
		setLoggedIn(false)
		logoutUser()
		navigate(routes.login)

	}
	return (
    <div className="min-h-screen bg-white">
      <SideNav />
      <div className="pl-[79px] md:pl-[96px] pb-5 pr-5">
        <div className="h-[106px] border-b border-b-F5F5F5 mb-5 flex justify-end items-center pr-5">
          <button
            className="w-[150px] bg-black text-white py-2 rounded-md disabled:opacity-40 h-10"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
        <div className="md:pl-[42px] md:pr-[44px]">{children}</div>
      </div>
    </div>
  );
};

export default Wrapper;
