// import useOutsideAlerter from "@/hooks/useClickOutside";
import { cn } from "../../utils/styling";
import { cva } from "class-variance-authority";
import { motion } from "framer-motion";
import { ReactNode, useRef } from "react";

const modalVariants = {
  hidden: {
    opacity: 0,
    y: "-100%",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};
export const slideOutVariant = {
  visible: {
    opacity: 0,
    y: "-100%",
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 1,
    y: 0,
  },
};


type Props = {
  children: ReactNode;
  className?: string;
  modalClassName?: any;
  variants?: any;
};

const ModalWrapper = ({
  children,
  className,
  modalClassName,
  variants,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  const defaultclassName = cva(
    `fixed top-0 left-0 w-full h-full ${"flex"} items-center justify-center z-10`
  );

  const defaultModalClass = cva(
    `w-max min-h-max bg-white rounded-lg z-50 shadow-xl modal`
  );

  return (
    <>
      <div
        className={cn(defaultclassName({ className }))}
        style={{
          backgroundColor: `${"rgba(0, 0, 0, 0.5)"} `,
        }}
        ref={ref2}
        aria-label="modal_wrapper"
      >
        <motion.div
          className={cn(defaultModalClass({ ...modalClassName }))}
          initial="hidden"
          animate="visible"
          variants={variants ?? modalVariants}
          ref={ref}
          aria-label="modal_content"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </motion.div>
      </div>
    </>
  );
};

export default ModalWrapper;
