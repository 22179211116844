import { CommaSeperatedNumber, PlainButton, Table } from "components/general";
import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { debounce } from "lodash";
import { SingleTransData } from "services/api/types";
import { useGetSingleAggTrans } from "services/api/path";
import ProgressBar from "components/ProgressBar";
import PaginationTab from "components/general/PaginationBar";
import TransactionModal from "pages/transaction/transactionModal copy";


type TransProps = {
  email: string;
};

const AggregatorWalletTrans = ({ email }: TransProps) => {
  const [query, setQuery] = React.useState("");
  const [page, setPage] = useState(1); 

  const {
    data: aggTrans,
    isLoading: isTransLoading,
    refetch: refetchTrans,
    isRefetching,
  } = useGetSingleAggTrans({
    path: email as string,
    queryParams: {
      page: page,
      limit: 20,
      filter: query ?? undefined,
    },
  });


  const [showTransactionModal, setShowTransactionModal] = React.useState(false);
  const [selectedTransaction, setSelectedTransaction] =
    React.useState<SingleTransData>();
  const openTransactionDetails = (transaction: SingleTransData) => {
    setSelectedTransaction(transaction);
    setShowTransactionModal(true);
  };

  //=======making a query search after every 3 seconds provided there's a searched query======//
  const debouncedSearch = React.useRef(
    debounce(() => {
      refetchTrans();
    }, 300)
  ).current;
  useEffect(() => {
    setPage(1);

    if (query) {
      debouncedSearch();
    } else if (query.length === 0) {
      setPage(1);
      refetchTrans();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch, refetchTrans]);
  //==================================================================

  useEffect(() => {
    window.scrollTo(0, 0);
    refetchTrans();
  }, [page, refetchTrans]);

  const columns: TableColumn<SingleTransData>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      cell: (row, index) => (
        <>
          {aggTrans?.meta.currentPage
            ? index + 1 + 10 * (aggTrans.meta.currentPage - 1)
            : ""}
        </>
      ),
      width: "70px",
    },
    {
      name: "Transaction ID",
      //   selector: (row) => row.transactionId,
      cell: (row) => <div className="text-black">{row.transactionId}</div>,
    },
    {
      name: "Transaction Amount",
      selector: (row) => row.amount,
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A `}
          value={row.amount}
        />
      ),
    },
    {
      name: "Commision",
      width: "17%",
      selector: (row) => row.transChannel || 0,
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A`}
          value={row.transactionId}
        />
      ),
    },
    {
      name: "Sub-Agent Code",
      selector: (row) => row.transType,
      cell: (row) => {
        return <>{row.transType}</>;
      },
    },   
    {
      name: "ACTION",
      cell: (row) => (
        <button
          onClick={() => openTransactionDetails(row)}
          className="mx-auto text-0067B4 font-productsans700 text-base"
        >
          View details
        </button>
      ),
    },
  ];

  if (isTransLoading) return <ProgressBar />;
  return (
    <div className="flex flex-col space-y-6">
      {showTransactionModal && (
        <TransactionModal
          visible={showTransactionModal}
          onClose={() => setShowTransactionModal(false)}
          data={selectedTransaction!}
        />
      )}
      <div className="flex items-center justify-between">
        <input
          className="w-1/2 border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
          placeholder="Search with transaction number, amount or date"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="flex gap-2 ">
          <PlainButton title="Send Push" />
          <PlainButton title="Filter records" />          
        </div>
      </div>
      <div className={`${isRefetching ? "opacity-30" : ""} space-y-7`}>
        <Table
          columns={columns}
          data={aggTrans == null ? [] : aggTrans?.content!}
        />
      </div>
      <div className="mb-6">
        <PaginationTab
          currentPage={page}
          setCurrentPage={setPage}
          totalPages={aggTrans?.meta.totalPages!}
        />
      </div>
    </div>
  );
};

export default AggregatorWalletTrans;
