import { Wrapper } from "components/navigation";
import { useState } from "react";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PersonalWalletTab from "./tabs/personalWalletTab";
import GroupWalletTab from "./tabs/groupWalletTab";
import BillPaymentTab from "./tabs/billPaymentTab";
import clsx from "classnames";

const DashboardPage = () => {
  // const [tabIndex, setTabIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(TABS[0].id);

  return (
    <Wrapper>
      <div className="mb-6">
        <h1 className="text-[22px] text-[#05004E] font-bold">
          Ourgwala Report
        </h1>
        <p className="text-lg text-[#737791]">
          Summary of users, transactions and platform activities
        </p>
      </div>
      <div className="">
        {/* <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>Personal Wallet</Tab>
            <Tab>Group Wallet</Tab>
            <Tab>Bill Payment</Tab>
          </TabList>

          
          <TabPanel>
            <PersonalWalletTab />
          </TabPanel>
          <TabPanel>
            <GroupWalletTab />
          </TabPanel>
          <TabPanel>
            <BillPaymentTab />
          </TabPanel>
        </Tabs> */}
        <div className="flex space-x-7 items-center mb-6">
          {TABS.map((tab) => (
            <button
              onClick={() => setActiveTab(tab.id)}
              className={clsx(
                "text-BBB5B5 transition-all font-productsans text-xl border-b-[4px] border-b-transparent",
                {
                  "!border-b-273B4A font-productsans700 !text-273B4A":
                    activeTab === tab.id,
                }
              )}
            >
              {tab.name}
            </button>
          ))}
        </div>
        {activeTab === "p_wall" ? (
          <PersonalWalletTab />
        ) : activeTab === "g_wall" ? (
          <GroupWalletTab />
        ) : (
          <BillPaymentTab />
        )}
      </div>
    </Wrapper>
  );
};

export default DashboardPage;

const TABS = [
  { name: "Personal Wallet", id: "p_wall" },
  { name: "Group Wallet", id: "g_wall" },
  { name: "Bill Payment", id: "bill_p" },
];
