import React from "react";
import clsx from "classnames";
import { NavLink } from "react-router-dom";
import { ReactComponent as Messages } from "assets/icons/navigation/Vectormessages.svg";
import { ReactComponent as Dashboard } from "assets/icons/navigation/Vectordashboard.svg";
import { ReactComponent as Groups } from "assets/icons/navigation/Vectorgroups.svg";
import { ReactComponent as Aggregators } from "assets/icons/navigation/Groupagg (1).svg";
import { ReactComponent as Commissions } from 'assets/icons/navigation/Vectorcommission.svg';
// import { ReactComponent as Settings } from 'assets/icons/navigation/settings.svg';
import { ReactComponent as Users } from "assets/icons/navigation/Vectorusers.svg";
import { ReactComponent as Transactions } from "assets/icons/navigation/Group 1410114046trans.svg";
import { routes } from "utils/constants";

const links = [
  { icon: <Dashboard />, route: routes.dashboard },
  { icon: <Users />, route: routes.users },
  { icon: <Transactions />, route: routes.transactions },
  { icon: <Groups />, route: routes.groups },
  { icon: <Messages />, route: routes.transHistory },
  { icon: <Aggregators />, route: routes.aggregators },
  { icon: <Commissions />, route: routes.commissions },
];

const SideNav = () => {
  return (
    <div className="md:w-[96px] px-[13px] left-0 top-0 h-screen fixed border-r border-r-F5F5F5 py-[40px]">
      <div className="flex pb-[30px] items-center justify-center border-b border-b-black/[.2]">
        <div className="h-9 w-9 rounded-full bg-black" />
      </div>
      <div className="flex flex-col pb-[85px] space-y-[60px] overflow-y-auto pt-[37.17px] scrollbar-hide items-center">
        {links.map((link, index) => (
          <div
            className="h-[26px] w-[26px] flex items-center justify-center"
            key={index}
          >
            <NavLink
              to={link.route}
              className={({ isActive }) =>
                clsx(
                  { "active-sidenav-link": isActive },
                  { "inactive-sidenav-link": !isActive }, 'scale-[.35]'
                )
              }
            >
              {link.icon}
            </NavLink>
          </div>
        ))}
      </div>
      {/* <div className="absolute bottom-0 w-full left-0 h-[85px] bg-[white] z-[2] flex justify-center">
				<NavLink
					to={'/settings'}
					className={({ isActive }) =>
						clsx(
							{ 'active-sidenav-link': isActive },
							{ 'inactive-sidenav-link': !isActive }
						)
					}>
					<Settings />
				</NavLink>
			</div> */}
    </div>
  );
};

export default SideNav;
