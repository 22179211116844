export const routes = {
  login: "/",
  users: "/users",
  groups: "/groups",
  transactions: "/transactions",
  transHistory: "/messages/message-history",
  user_detail: "/users/:email",
  dashboard: "/dashboard",
  aggregators: "/aggregators",
  commissions: "/commissions",
  aggregator_detail: "/aggregators/:email",
  getUserDetailRoute: (userId: string) => `/users/${userId}`,
};

export const NA = "N/A";

export const defaultTrans = {
  transactionId: 0,
  amount: 0,
  transactionType: "",
  transactionStatus: "",
  transactionChannel: "",
  transactionDate: "",
  productDescription: "",
  referenceId: null,
  paymentId: null,
  paymentSources: "",
  productName: null,
  fullName: "",
  sender: "",
  receiver: "",
  productOption1: "",
  productOption2: null,
  productOption3: null,
  transactionOperationType: "",
};
