import clsx from "classnames";
import Axios from "services/api";

export const getInputClassNames = (hasError: boolean) => {
  const inputClassNames =
    "input border-D9D9D9 rounded font-manrope border w-full py-2 px-4 outline-none";
  return clsx(inputClassNames, { "!border-[red]": hasError });
};


export function processFloatingNumber(num: number) {
  if (Number.isFinite(num) && !Number.isInteger(num)) {
    // It's a floating-point number
    const roundedNumber = parseFloat(num.toFixed(2));
    return roundedNumber;
  } else {
    // It's not a floating-point number
    return num;
  }
}
const token = localStorage.getItem("token");

const headers = {
  Authorization: `Bearer ${token}`,
};
export const exportCallFunction = async (url: string) => {
  const response = await Axios.get(url, {
    responseType: "blob",
  }); 
  return response;
};

export const generateFileFromBlob = async (
  file: any,
  filename: string,
  fileType: string
) => {
  try {
    const url = window.URL.createObjectURL(
      new Blob([file], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename}.${fileType}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error downloading file:", error);
  }
};

export const handleExportDownload = async (url: string, name: string, token : string) => {
  // Replace 'YOUR_TOKEN' with the actual token
  const bearerToken = token;
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
  };

  try {
    const response = await fetch(
      `https://adminapi.ourgwala.com/${url}`,
      options
    );

    // Handle the response, for example, downloading the file
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else {
      // Handle errors
      console.error("Failed to download file:", response.statusText);
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
};


export function getNonEmptyFields(obj: any): any {
  const nonEmptyFields: any = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== "" && obj[key] !== 0) {
      nonEmptyFields[key] = obj[key];
    }
  }

  return nonEmptyFields;
}

export const statsDetText = (x :string) => {
   if (x === "allTime") {
     return "from all time";
   } else if (x === "lastWeek") {
     return "from 7 days";
   } else if (x === "30days") {
     return "from 30 days";
   } else if (x === "today") {
     return "today";
   } else if (x === "60days") {
     return "from 60 days";
   }
 };

export function pickFirstTwoNames(fullName : string) {
   const names = fullName.split(" ");
   const [firstName, secondName] = names;
   return firstName && secondName ? `${firstName} ${secondName}` : fullName;
 }