import React, { useEffect, useState } from "react";
import { Table } from "components/general";
import { MessagesTableType } from "interfaces/messages";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { routes } from "utils/constants";
import { debounce } from "lodash";
import { useGetAdminMessagesAuto } from "services/api/transactionPaths";
import PaginationTab from "components/general/PaginationBar";
interface AutomatedMessagesProps {
  messages: MessagesTableType[];
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const AutomatedMessages = ({
  messages,
  closeModal,
}: AutomatedMessagesProps) => {
  const navigate = useNavigate();
  const [query, setQuery] = React.useState("");
  const [page, setPage] = useState(1);

  const { data: autoMessagesData, refetch: refetchMessages } =
    useGetAdminMessagesAuto({
      queryParams: {
        limit: 20,
        page: page,
        filter: query,
      },
    });

  const columns: TableColumn<MessagesTableType>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      sortable: false,
      width: "70px",
    },
    {
      name: "MESSAGE TITLE",
      selector: (row) => row.title,
      width: "21%",
    },
    {
      name: "CREATED ON",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "LAST DELIVERED",
      width: "18%",
      selector: (row) => row.group ?? "N/A",
      cell: (row) => (
        <div className="mx-auto">
          {row.group ? (
            <div className="bg-C4C4C4 h-5 flex items-center justify-center px-3 rounded-50">
              <span className="font-productsans text-[12px] text-273B4A">
                {row.group}
              </span>
            </div>
          ) : (
            <p className="h-5 flex items-center justify-center px-3 rounded-50">
              N/A
            </p>
          )}
        </div>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <button
          onClick={() => console.log("messages page")}
          className="mx-auto text-0067B4 font-productsans700 text-base"
        >
          View
        </button>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <button
          className="mx-auto text-0067B4 font-productsans700 text-base"
        >
          Edit
        </button>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <button
          className="mx-auto text-FF0053 font-productsans700 text-base"
        >
          Delete
        </button>
      ),
    },
  ];

  //=======making a query search after every 3 seconds provided there's a searched query======//
  const debouncedSearch = React.useRef(
    debounce(() => {
      refetchMessages();
    }, 300)
  ).current;

  useEffect(() => {
    setPage(1);

    if (query) {
      debouncedSearch();
    } else if (query.length === 0) {
      setPage(1);
      refetchMessages();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [query, debouncedSearch, refetchMessages]);
  //==================================================================

   useEffect(() => {
     refetchMessages();
     window.scrollTo(0, 0);
   }, [page, refetchMessages]);

  return (
    <div className="">
      <span className="font-productsans text-273B4A text-base">
        <span onClick={() => navigate(routes.users)} className="cursor-pointer">
          Home
        </span>
        {" > "}{" "}
        <span onClick={() => closeModal(false)} className="cursor-pointer">
          {" "}
          Messages
        </span>
        {" > "}
        <span className="text-BBB5B5">Automated messages</span>
      </span>
      <div className="mt-8">
        <div className="flex items-center justify-between">
          <input
            className="w-1/2 border border-D9D9D9 rounded-5 pl-4 outline-none h-[41px] flex-shrink-0 font-productsans text-[15px] text-273B4A placeholder:text-BBB5B5"
            placeholder="Search keywords, titles or phrases"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
          <div className="flex space-x-[14px]">
            {/* <PlainButton title="Automated messages" /> */}
          </div>
        </div>
      </div>
      <div className="mt-8 space-y-7">
        <Table columns={columns} data={autoMessagesData?.sanitizedItems!} />
      </div>
      <div className="mb-6">
        <PaginationTab
          currentPage={page}
          setCurrentPage={setPage}
          totalPages={autoMessagesData?.meta.totalPages!}
        />
      </div>
    </div>
  );
};

export default AutomatedMessages;
