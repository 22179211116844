import { useState } from "react";
import Switch from "react-switch";

type Props = {
  title: string;
  desc: string;
};

const DetailsCard = ({ title, desc }: Props) => {
  return (
    <div className="border border-C4C4C4 rounded-5 min-h-[75px] p-[.9rem] font-productsans flex justify-between flex-col">
      <h3 className="m-0 mb-[9px] text-545454 text-[17px]">{title}</h3>
      <p className="text-2xl text-black leading-none">{desc}</p>
    </div>
  );
};

export default DetailsCard;

type switchProps = {
    text : string
}
export const SwitchText = ({text} : switchProps) => {
    const [checked, setChecked] = useState(false)
  return (
    <p className="text-[21px] text-black leading-none flex justify-between items-center">
      {text}
      <Switch
        onChange={() => setChecked(!checked)}
        checked={checked}
        checkedIcon={false}
        uncheckedIcon={false}
        className="scale-75"
        onColor="#4FD000"      
      />
    </p>
  );
};
