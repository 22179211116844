import { CommaSeperatedNumber } from "components/general";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/general/Accordion/index2";
import { ArrowRightCircle } from "lucide-react";
import { useState } from "react";
import { EarningByTrans, EarningByUser, GwalaEarningsByFees } from "services/api/types";

type DataProps = {
  data:
    | {
        userWalletPaymentValue: number;
        groupWalletPaymentValue: number;
        billsPaymentValue: number;
      }
    | {
        userWalletPaymentVolume: number;
        groupWalletPaymentVolume: number;
        billsPaymentVolume: number;
      }
    | {
        zenithTransferCount: number;
        offlinePaymentCount: number;
        cardTransferCount: number;
        primeAirtimeCount: number;
      }
    | EarningByTrans
    | EarningByUser | GwalaEarningsByFees;
  header: string;
  subHeader: string;
};

const TransactionAccordion = ({ data, header, subHeader }: DataProps) => {
  const [showFull, setShowFull] = useState(false);
  const checkWord = subHeader.trim().split(" ").pop();
  const isDecimal = checkWord !== "volume" && checkWord !== "channel";

  return (
    <Accordion type="single" collapsible>
      <AccordionItem
        value="item-1"
        className={`pt-[14px] pl-4 pr-[11px] border-C4C4C4 border rounded-5`}
      >
        <AccordionTrigger className="text-left">
          <div className="" onClick={() => setShowFull(!showFull)}>
            <div className="flex justify-between cursor-pointer items-center gap-20">
              <h1 className="text-lg whitespace-nowrap">{header}</h1>
              <ArrowRightCircle
                className={`${showFull ? "-rotate-90" : ""} transition-all`}
              />
            </div>
            <p className="text-sm mb-2">{subHeader}</p>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="mt-[10px]">
            {/* {header === '' && } */}
            {Object.entries(data).map(([key, value], i) => (
              <p
                key={i}
                className={` py-1 flex w-full justify-between ${
                  Object.keys(data).length - 1 === i
                    ? ""
                    : "border-b border-[#F5F5F5]"
                }`}
              >
                {key}
                {/* <span className="">{processFloatingNumber(value)}</span> */}
                <span className="">
                  {isDecimal ? (
                    <CommaSeperatedNumber
                      className={`font-productsans text-base text-273B4A`}
                      value={value}
                    />
                  ) : (
                    value
                  )}
                </span>
              </p>
            ))}
            {/* {data.data.map((option, i) => (
              <p
                className={` py-1 flex w-full justify-between ${
                  data.data.length - 1 === i ? "" : "border-b border-[#F5F5F5]"
                }`}
              >
                {option.title}
                <span className="">{option.num}</span>
              </p>
            ))} */}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default TransactionAccordion;
