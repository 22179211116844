import { CommaSeperatedNumber, PlainButton, Table } from "components/general";
import { useState } from "react";
import { TableColumn } from "react-data-table-component";
import { SingleTransData } from "services/api/types";
import StatusColor from "components/utils/StatusColor";
import { useGetSingleUserPendingTrans } from "services/api/path";
import ProgressBar from "components/ProgressBar";
import PaginationTab from "components/general/PaginationBar";
import { handleExportDownload } from "utils/functions";
import { useAuthContext } from "context";

type PendingTableProps = {
  email :string
};
const UserPendingTransactions = ({ email }: PendingTableProps) => {
  const [page, setPage] = useState(1);
  const { authToken } = useAuthContext();

  const { data: userPendingTrans, isLoading: isPendTransLoading } =
    useGetSingleUserPendingTrans({
      path: email as string,
      queryParams: {
        page: page,
        limit: 20,
      },
    });


  const columns: TableColumn<SingleTransData>[] = [
    {
      name: "S/N",
      selector: (row, index) => (index || 0) + 1, // Assign the row number to the cell
      cell: (row, index) => (
        <>
          {userPendingTrans?.meta.currentPage
            ? index + 1 + 10 * (userPendingTrans.meta.currentPage - 1)
            : ""}
        </>
      ),
      width: "70px",
    },
    {
      name: "Transaction ID",
      //   selector: (row) => row.transactionId,
      cell: (row) => <div className="text-black">{row.transactionId}</div>,
      sortable: true,
    },
    {
      name: "Transaction Status",
      // width: '175px',
      cell: (row) => {
        return (
          <div className="mx-auto">
            <StatusColor status={row.transStatus} />
          </div>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => (
        <CommaSeperatedNumber
          className={`font-productsans text-base text-273B4A `}
          value={row.amount}
        />
      ),
    },
    {
      name: "Type",
      sortable: true,
      selector: (row) => row.transType,
      cell: (row) => {
        return <>{row.transType}</>;
      },
    },
    {
      name: "Payment channel",
      sortable: true,
      selector: (row) => row.transChannel,
      cell: (row) => {
        return <>{row.transChannel}</>;
      },
    },
    {
      name: "ACTION",
      cell: (row) => (
        <button
          // onClick={() => navigate(routes.getUserDetailRoute("1"))}
          className="mx-auto text-0067B4 font-productsans700 text-base"
        >
          View details
        </button>
      ),
    },
  ];
  if (isPendTransLoading) return <ProgressBar />;

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex items-center justify-end">
        <div className="flex gap-2 ">
          <PlainButton title="Send Push" />
          <PlainButton title="Filter records" />
          {userPendingTrans?.content?.length! > 0 && (
            <PlainButton title="Export" onClick={()=>handleExportDownload(`users/export-pending-transactions/${email}`,`${email}-pending-transasctions`,authToken!)}/>
          )}
        </div>
      </div>
      <Table
        data={userPendingTrans?.content!}
        columns={columns}
        noDataComponentDescription="All pending transactions for this user will be displayed here"
        noDataComponentHeader="No Pending Transactions"
      />
      <div className="mb-6">
        <PaginationTab
          currentPage={page}
          setCurrentPage={setPage}
          totalPages={userPendingTrans?.meta.totalPages!}
        />
      </div>
    </div>
  );
};

export default UserPendingTransactions;
