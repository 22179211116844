import React from 'react';
import { NumericFormat } from 'react-number-format';

interface CommaSeperatedNumberProps {
	className: string;
	isInput?: boolean;
	value?: number | null;
	hasDecimalPlaces?:boolean
}

const CommaSeperatedNumber = ({
	className,
	isInput = false,
	value,
	hasDecimalPlaces=true
}: CommaSeperatedNumberProps) => {
	return (
		<NumericFormat
			className={className}
			displayType={isInput ? 'input' : 'text'}
			decimalScale={2}
			fixedDecimalScale={!!hasDecimalPlaces}
			thousandSeparator=","
			value={value}
		/>
	);
};

export default CommaSeperatedNumber;
