import { useEffect, useMemo } from "react";
import { useGetGroupWalletTransactionsStats } from "services/api/transactionPaths";
import { useDashboardContext } from "context";
import { DashboardGroupWallet } from "services/api/types";
import DashboardCard from "../cards/dashboardCardGroup";
import ProgressBar from "components/ProgressBar";

const GroupWalletTab = () => {
  const { groupParams } = useDashboardContext();

  const {
    data: groupWalletStats,
    refetch: refetchStats,
    isLoading,
    isRefetching,
  } = useGetGroupWalletTransactionsStats({
    queryParams: groupParams,
  });

  const groupWalletStatsData = useMemo(
    () => groupWalletStats ?? {},
    [groupWalletStats]
  );
  useEffect(() => {
    refetchStats();
  }, [groupParams, refetchStats]);

  if (isLoading) return <ProgressBar />;


  return (
    <div className="grid grid-cols-4 col-span-4 justify-between gap-6 mb-8">
      {Object.keys(groupWalletStatsData as DashboardGroupWallet).map(
        (key, i) => (
          <DashboardCard
            num={i}
            name={`${key}`}
            deets={groupWalletStats!}
            refetching={isRefetching}
            key={i}
          />
        )
      )}
    </div>
  );
};

export default GroupWalletTab;
