import { Wrapper } from "components/navigation";
import GroupsCard from "./groupsCard";
import Groups from "./totalGroups";

const GroupPage = () => {
  return (
    <Wrapper>
      <div className="">
        <GroupsCard />
        <Groups />
      </div>
    </Wrapper>
  );
};

export default GroupPage;
