import React from 'react';
import GwalaRoutes from 'GwalaRoutes';
import AuthContextProvider from 'context/auth';
import ScrollToTop from 'components/utils/ScrollToTop';

function App() {
	return (
    <AuthContextProvider>
      <ScrollToTop/>
      <GwalaRoutes />
    </AuthContextProvider>
  );
}

export default App;
