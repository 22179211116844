import StatsCard from "components/general/StatsCard";
import { newDropDownOptions } from "interfaces/transactions";
import { useEffect, useState } from "react";
import { useGetGroupsStats } from "services/api/transactionPaths";

const GroupsCard = () => {
  const [groupsStatsParams, setGroupsStatsParams] = useState({
    totalNumberOfGroups: "allTime",
    totalgroupMembers: "allTime",
    totalGroupWalletBalance: "allTime",
  });

  const handleParamsChange = (val: string, name: string) => {
    setGroupsStatsParams({
      ...groupsStatsParams,
      [name]: val,
    });
  };
  const { data: groupStats, refetch: refetchStats, isRefetching } = useGetGroupsStats({
    queryParams: groupsStatsParams,
  });

  useEffect(() => {
    refetchStats();
  }, [groupsStatsParams, refetchStats]);

  return (
    <div className="">
      <div className={`flex gap-4 mt-5 ${isRefetching ? 'opacity-30' : ''}`}>
        <StatsCard
          header="TOTAL GROUPS"
          dropDownOptions={newDropDownOptions}
          num={groupStats?.totalNumberOfGroups.value!}
          text={groupsStatsParams.totalNumberOfGroups}
          name="totalNumberOfGroups"
          percent={`${groupStats?.totalNumberOfGroups.change ?? 0}`}
          decimal={true}
          width={302}
          handleSelect={handleParamsChange}
        />
        <StatsCard
          header="TOTAL GROUP MEMBERS"
          dropDownOptions={newDropDownOptions}
          num={groupStats?.totalgroupMembers.value!}
          text={groupsStatsParams.totalgroupMembers}
          name="totalgroupMembers"
          percent={`${groupStats?.totalgroupMembers.change ?? 0}`}
          decimal={true}
          handleSelect={handleParamsChange}
        />
        <StatsCard
          header="GROUP WALLET BALANCE"
          dropDownOptions={newDropDownOptions}
          num={groupStats?.totalGroupWalletBalance.value!}
          text={groupsStatsParams.totalGroupWalletBalance}
          name="totalGroupWalletBalance"
          percent={`${groupStats?.totalGroupWalletBalance.change ?? 0}`}
          decimal={true}
          handleSelect={handleParamsChange}
        />      
      </div>
    </div>
  );
};

export default GroupsCard;
