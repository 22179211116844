import { statsDetText } from "utils/functions";
import CommaSeperatedNumber from "./CommaSeperatedNumber";
import StyledSelect from "./StyledSelect";

type StatsProps = {
  num: number;
  text?: string;
  header: string;
  percent?: string;
  dropDownOptions?: {
    name: string;
    value: string;
  }[];
  width?: number;
  decimal: boolean;
  handleSelect: (val: string, name: string) => void;
  name: string;
};
const StatsCard = ({
  num,
  text,
  dropDownOptions,
  header,
  percent,
  width,
  decimal,
  name,
  handleSelect,
}: StatsProps) => {
  return (
    <div
      className={`pt-[.88rem] pb-[6px] pl-4 pr-[10px] border-C4C4C4 border rounded-5 flex flex-col justify-between `}
      style={{ width: `${width ? `${width}px` : "max-content"}` }}
    >
      <div className="flex items-center justify-between">
        <span className="font-productsans text-273B4A whitespace-nowrap">
          {header}
        </span>
        {dropDownOptions && (
          <StyledSelect
            options={dropDownOptions}
            handleSelect={handleSelect}
            name={name}
          />
        )}
      </div>
      <h2 className="">
        {/* {num} */}
        <CommaSeperatedNumber
          value={num}
          className={`text-273B4A font-mclaren ${
            header === "TOTAL INFLOW" ? "text-[40px]" : "text-[45px]"
          }`}
          hasDecimalPlaces={decimal}
        />
      </h2>
      {percent && (
        <p className="mt-[2px] text-273B4A font-productsans text-xs md:text-base">
          {`${percent}%`} {statsDetText(text!)}
        </p>
      )}
    </div>
  );
};

export default StatsCard;
