import React from "react";
import clsx from "classnames";
import { TransactionType } from "interfaces/transactions";
import { CommaSeperatedNumber } from "components/general";
import StatusColor from "components/utils/StatusColor";
import close from '../../assets/icons/general/close.svg'
import { format } from "date-fns";

interface TransactionModalProps {
  visible: boolean;
  onClose: () => void;
  data: TransactionType;
}

const TransactionModal = ({
  visible,
  onClose,
  data,
}: TransactionModalProps) => {
  const dataObject =
    data.transactionType === "ki"
      ? JSON.parse(data.productDescription)
      : data.productDescription;
  // const dateObj = parseISO(data.transactionDate);

  const formattedDate = format(new Date(data.transactionDate), "MMMM d, yyyy");
  const formattedTime = format(new Date(data.transactionDate), "h:mm a");

  return (
    <div
      role="presentation"
      onClick={onClose}
      className={clsx(
        "fixed top-0 left-0 z-[999] w-full h-screen opacity-0 pointer-events-none overflow-y-auto bg-black/[.6] flex justify-end",
        {
          "!opacity-100 !pointer-events-auto": visible,
        }
      )}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white py-[72px] px-[31px] pb-[50px] xl:w-[561px] flex flex-col overflow-y-scroll animate-slideright"
      >
        <div className="flex justify-between items-center mb-[52px] animate-slideleft">
          <h3 className=" text-273B4A text-[33px] font-productsans">
            Transaction Detail
          </h3>
          <span
            className="p-3 bg-D9D9D9 rounded-full cursor-pointer"
            onClick={onClose}
          >
            <img src={close} alt="" />
          </span>
        </div>

        <div className="border border-D9D9D9 w-full">
          <div className="font-productsans text-[18px] text-273B4A border-b border-b-D9D9D9 flex px-[22px] py-[10px]">
            <div className="w-3/5">
              <h1 className="text-[15px] font-extrabold">From</h1>
              <p className="text-xl">{data.sender}</p>
            </div>
            <div>
              <h1 className="text-[15px] font-extrabold">To</h1>
              <p className="text-xl">{data.receiver}</p>
            </div>
          </div>
          <div className="font-productsans text-[18px] text-273B4A border-b border-b-D9D9D9 flex px-[22px] py-[6px]">
            <div className="w-3/5">
              <h1 className="text-[15px] font-extrabold">Transaction #</h1>
              <p className="text-xl">{data.transactionId}</p>
            </div>
            <div>
              <h1 className="text-[15px] font-extrabold">Transaction Type</h1>
              <p>{data.transactionType}</p>
            </div>
          </div>
          <div className="font-productsans text-[18px] text-273B4A border-b border-b-D9D9D9 flex px-[22px] ">
            <div className="w-[28%] border-r py-[6px]">
              <h1 className="text-[15px] font-extrabold">Value</h1>
              <CommaSeperatedNumber
                className={`font-productsans text-xl text-273B4A`}
                value={data.amount}
              />
            </div>
            <div className="w-[40%] border-r py-[6px] pl-3">
              <h1 className="text-[15px] font-extrabold">Source</h1>
              <p>Gwala Accounting</p>
              <p>{dataObject?.receiverAccount}</p>
            </div>
            <div className="py-[6px] pl-3">
              <h1 className="text-[15px] font-extrabold">Destination</h1>
              <p>{dataObject?.payerAccount}</p>
              {/* <p>GT Bank</p>
              <p>0277827717</p> */}
            </div>
          </div>
          <div className="font-productsans text-[18px] text-273B4A flex px-[22px] ">
            <div className="w-[28%] border-r py-[6px]">
              <h1 className="text-[15px] font-extrabold">Date</h1>
              <p className="text-xl">{formattedDate}</p>
            </div>
            <div className="w-[40%] border-r py-[6px] pl-3">
              <h1 className="text-[15px] font-extrabold">Time</h1>
              <p>{formattedTime}</p>
            </div>
            <div className="py-[6px] pl-3">
              <h1 className="text-[15px] font-extrabold">Transaction Status</h1>
              {/* {data.status === "Pending" ? (
                <div className="bg-[#FFACC7] h-5 flex items-center justify-center mt-1 px-3 rounded-50">
                  <span className="font-productsans text-[12px] text-273B4A">
                    Pending
                  </span>
                </div>
              ) : (
                <div className="bg-E5FDD1 h-5 flex items-center justify-center mt-1 px-3 rounded-50">
                  <span className="font-productsans text-[12px] text-273B4A">
                    Successful
                  </span>
                </div>
              )} */}
              <div className="mx-auto">
                <StatusColor status={data.transactionStatus} />
              </div>
            </div>
          </div>
        </div>

        <div className="border border-D9D9D9 w-full mt-[18px] mb-10 px-[22px] py-[12px]">
          <h1 className="text-[15px] font-extrabold">Description</h1>
          <p className="text-[18px]">No description was provided</p>
        </div>

        {false && (
          <>
            <h1 className="text-[25px] font-normal mb-10">Transaction log</h1>

            <div className="log mb-24">
              <div className="flex items-center">
                <div className="w-1/4 border-r-[3px] border-b-D9D9D9 px-7 py-2">
                  <h2>Jul 12</h2>
                  <p>12:20pm</p>
                </div>
                <div className="flex gap-7 items-center ml-[-0.433rem]">
                  <span className="w-3 h-3 bg-[#4FD000] rounded-full"></span>
                  <p>Transaction initiated by sender</p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-1/4 border-r-[3px] border-b-D9D9D9 px-7 py-2">
                  <h2>Jul 12</h2>
                  <p>12:21pm</p>
                </div>
                <div className="flex gap-7 items-center ml-[-0.433rem]">
                  <span className="w-3 h-3 bg-[#4FD000] rounded-full"></span>
                  <p>Transaction pending delivery</p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-1/4 border-r-[3px] border-b-D9D9D9 px-7 py-2">
                  <h2>Jul 12</h2>
                  <p>12:31pm</p>
                </div>
                <div className="flex gap-7 items-center ml-[-0.433rem]">
                  <span className="w-3 h-3 bg-[#4FD000] rounded-full"></span>
                  <p>Transaction pending delivery</p>
                </div>
              </div>
            </div>
          </>
        )}
        {false && (
          <div className="buttons flex w-full gap-5">
            <button className="w-1/2 h-[58px] px-[13px] py-[10px] bg-FF0053 text-white text-[20px] font-productsans">
              Refund Account
            </button>
            <button className="w-1/2 h-[58px] px-[13px] py-[10px] bg-black text-white text-[20px] font-productsans">
              Approve Transfer
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionModal;
