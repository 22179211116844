import { createMutation } from "./mutation";
import { LoginResponse } from "./types";

export const keyAdmin = [`/admin/Auth/Login`];

// export const useLoginAdmin = createQuery<LoginResponse>({
//   key : keyAdmin
// });

export const useLoginAdmin = createMutation<LoginResponse>({
  url: "/auth/login",
  method: "POST",
});

