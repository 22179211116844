import { GwalaUser2 } from "interfaces/users";
import React, { createContext, useEffect, useState } from "react";

// type SortColumn = { id: string | undefined; desc: boolean | undefined };

export type defaultAggregatorProps = {
  selectedAggregator: GwalaUser2 | undefined;
  setSelectedAggregator: React.Dispatch<React.SetStateAction<GwalaUser2 | undefined>>;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageNumberClicked: boolean;
  setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultAggregatorState: defaultAggregatorProps = {
  selectedAggregator: undefined,
  setSelectedAggregator: () => undefined,
  pageNumber: 1,
  setPageNumber: () => undefined,
  pageNumberClicked: false,
  setPageNumberClicked: () => undefined,
};

export const AggregatorContext = createContext(defaultAggregatorState);

export const AggregatorContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedAggregator, setSelectedAggregator] = useState<GwalaUser2 | undefined>();
  const [pageNumberClicked, setPageNumberClicked] = useState(false);

  useEffect(() => {
    setSelectedAggregator(selectedAggregator);
  }, [selectedAggregator]);
  const value = {
    pageNumber,
    setPageNumber,
    selectedAggregator,
    setSelectedAggregator,
    pageNumberClicked,
    setPageNumberClicked,
  };
  return <AggregatorContext.Provider value={value}>{children}</AggregatorContext.Provider>;
};
